import React, { useState } from "react";
import { Button, Modal, ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./gifplayer.css";

function HelpRegex() {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="link"
        size="sm"
        onClick={() => setShow(true)}
        aria-label="Help Popover Button"
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Button>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Regular Expression Examples</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroupItem>
              <h4 className="list-group-item-heading">Limit Characters</h4>
              <span>
                ^[a-zA-Z0-9]*$&nbsp;&nbsp;&nbsp;&nbsp;Alpha-numeric characters
                (any length or empty)
              </span>
              <br />
              <span>
                ^[0-9]*$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Numbers
                only (any length or empty)
              </span>
              <br />
              <span>
                ^[a-zA-Z\s_]*$&nbsp;&nbsp;&nbsp;&nbsp;Alpha-numeric characters +
                space + underscore (any length or empty)
              </span>
              <br />
            </ListGroupItem>
            <ListGroupItem>
              <h4 className="list-group-item-heading">Limit Length</h4>
              <span>
                ^[a-zA-Z0-9]&#123;6&#125;$&nbsp;&nbsp;&nbsp;&nbsp;Exactly 6
                (alpha-numeric characters)
              </span>
              <br />
              <span>
                ^[0-9]&#123;1,4&#125;$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Between
                1-4 numerical characters
              </span>
              <br />
              <span>
                ^[a-zA-Z0-9]&#123;0,30&#125;$&nbsp;&nbsp;&nbsp;&nbsp;Up to 30
                alpha-numeric characters
              </span>
              <br />
            </ListGroupItem>
            <ListGroupItem>
              <h4 className="list-group-item-heading">Limit numerical value</h4>
              <span>
                ^[2-9][0-9]|[1][0-4][0-9]|[1][5][0-2]$&nbsp;&nbsp;&nbsp;&nbsp;Numerical
                value between 20 and 152
              </span>
            </ListGroupItem>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default HelpRegex;
