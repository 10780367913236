import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { isSmartClassFiber } from "../products/productInfo.js";

import JobInfoInput from "./JobInfoInput.js";

export default function JobInformation({ job, setJob, productInfo }) {
  function updateJobInfo(name, value, index = -1) {
    if (value.length < 61) {
      if (index >= 0) {
        job[name][index] = value;
      } else {
        job[name] = value;
      }
      setJob(job);
    }
  }

  const jobInfo = makeJobInfoInputs(job, updateJobInfo, productInfo);
  return (
    <Card>
      <Card.Body>
        <h4>
          <FormattedMessage
            id="jobinformation.title"
            defaultMessage="Job Information"
          />
          <br />
          <small>
            <FormattedMessage
              id="jobinformation.description"
              defaultMessage="Job information will be saved in all reports"
            />
          </small>
        </h4>
        {jobInfo}
      </Card.Body>
    </Card>
  );
}

function makeJobInfoInputs(job, updateJobInfo, productInfo) {
  let localJob = Object.assign({}, job);
  delete localJob.testPlan;
  if (productInfo.isOnx || isSmartClassFiber(productInfo.product))
    delete localJob.attributesPlan;
  let jobInfo = Object.entries(localJob).map(([key, value], index) => (
    <JobInfoInput
      productInfo={productInfo}
      value={value}
      field={key}
      updateJobInfo={updateJobInfo}
      key={index}
    />
  ));
  return jobInfo;
}
