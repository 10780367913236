import { useState, useEffect } from "react";
import { Button, Modal, FormControl, FormGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import useSimulateDelay from "../hooks/useSimulateDelay.js";

import { getTechnicianList } from "../stratasync/adapterFunctions.js";
import { checkWorkflowLicense } from "../stratasync/api.js";
import { isSmartClassFiber } from "../products/productInfo";
import { EmptyLocationsDialog } from "./EmptyLocationsDialog";

function SendJobButton({
  productInfo,
  ssServer,
  accessToken,
  sendJobToStrataSync,
  validateFields,
  isTestLocationsListEmpty,
}) {
  const [workflowLicensed, setWorkflowLicensed] = useState(false);
  const [isDisabled, setDisabled] = useSimulateDelay(false);
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function determineWorkflowLicensed() {
      const result = await checkWorkflowLicense(ssServer, accessToken);
      if (result) {
        setWorkflowLicensed(true);
      }
    }
    determineWorkflowLicensed();
  }, [ssServer, accessToken]);

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  const handleClick = () => {
    var isValid = validateFields();

    if (isSmartClassFiber(productInfo.product) && isTestLocationsListEmpty()) {
      handleClickOpen();
    } else {
      setDisabled(!isValid);
      if (isValid) {
        handleShow();
      }
    }
  };

  return (
    <div>
      <Button
        variant="outline-primary"
        onClick={!isDisabled ? handleClick : null}
        id="send-job"
        disabled={!workflowLicensed}
      >
        {!isDisabled ? (
          <FormattedMessage
            id="sendjobbutton.message"
            defaultMessage={"Send to Instrument"}
          />
        ) : (
          <FormattedMessage
            id="sendjobbutton.message.invalid"
            defaultMessage={"Fields Invalid!"}
          />
        )}
      </Button>
      <SelectTechModal
        show={show}
        handleClose={handleClose}
        productInfo={productInfo}
        ssServer={ssServer}
        accessToken={accessToken}
        sendJobToStrataSync={sendJobToStrataSync}
      />
      <EmptyLocationsDialog
        openDialog={open}
        handleCancel={handleCancel}
        warningText={
          "Cannot send job without test locations list to instrument."
        }
      />
    </div>
  );
}

function SelectTechModal({
  show,
  handleClose,
  productInfo,
  ssServer,
  accessToken,
  sendJobToStrataSync,
}) {
  const [technicianList, setTechnicianList] = useState([]);
  useEffect(() => {
    async function fetchTechnicians() {
      const technicianList = await getTechnicianList(
        ssServer,
        accessToken,
        productInfo.ssAssets
      );
      setTechnicianList(technicianList);
    }
    fetchTechnicians();
  }, [ssServer, accessToken, productInfo]);

  const [searchString, setSearchString] = useState("");

  const filteredTechList = technicianList.filter(
    (technician) =>
      technician.techFullName
        .toLowerCase()
        .includes(searchString.toLowerCase()) ||
      technician.techId.toLowerCase().includes(searchString.toLowerCase())
  );
  const technicianButtons = filteredTechList.map((technician, index) => (
    <SendToTechButton
      technician={technician}
      key={index}
      sendJobToStrataSync={sendJobToStrataSync}
    />
  ));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Technician</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormControl
            type="text"
            value={searchString}
            placeholder="Search"
            onChange={(e) => setSearchString(e.target.value)}
          />
        </FormGroup>
        <div
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          {technicianButtons}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SendToTechButton(props) {
  const [sent, setSent] = useState(false);

  async function send() {
    const sentStatus = await props.sendJobToStrataSync(props.technician.techId);
    setSent(sentStatus);
  }

  return makeButton(props.technician, send, sent);
}

function makeButton(technician, send, sent) {
  if (!sent) {
    return (
      <Button
        variant="outline-primary"
        id={"send-to-tech-" + technician.techId}
        onClick={send}
        block
      >
        {technician.techFullName + " (" + technician.techId + ")"}
      </Button>
    );
  } else {
    return (
      <Button id={"job-sent-" + technician.techId} variant="success" block>
        Job Sent!
      </Button>
    );
  }
}

export { SendJobButton, SendToTechButton };
