import {
  initialJob4000,
  initialJob5800,
  initialJobOneExpertDSL,
  initialJobOneExpertCATV,
  initialJobNSC100,
  initialJobOneExpertDSP,
  initialJobSCF,
  initialJobRFVision,
} from "./initialJobs.js";
import testTypes5800 from "./testTypes5800.js";
import testTypes4000 from "./testTypes4000.js";
import testTypesOna800 from "./testTypesOna800.js";
import testTypesCA5G from "./testTypesCA5G.js";
import testTypesOneExpertCATV from "./testTypesOneExpertCATV.js";
import testTypesOneExpertDSL from "./testTypesOneExpertDSL.js";
import testTypesNSC100 from "./testTypesNSC100.js";
import testTypesOneExpertDSP from "./testTypesOneExpertDSP.js";
import testTypesRFVision from "./testTypesRFVision.js";
import {
  isWavelengthRange,
  getWavelengthsFromTestType,
} from "../components/utilities.js";
import { testTypes as testTypesMap } from "../constants/testTypes";

export const productKeys = {
  scf: "scf",
  rfv: "rfv",
};

export const displayLabels = {
  5800: "T-BERD/MTS-5800",
  4000: "T-BERD/MTS-2000, -4000, SmartOTDR",
  ona800: "ONA-800",
  ca5g: "CellAdvisor5G",
  onx580: "OneExpert DSL",
  onxcatv: "OneExpert CATV",
  nsc100: "NSC-100",
  onx220: "OneExpert DSP",
  scf: "SmartClass Fiber",
  rfv: "RF Vision",
};

export function isSmartClassFiber(product) {
  return product === "scf";
}

export function getDeployableToProducts(ssAssets, testType) {
  let deployableTo = [];
  if (!ssAssets) {
    return deployableTo;
  }
  if (ssAssets.includes(displayLabels.scf)) {
    const idf = getInstrumentDefinitionFile(productKeys.scf);
    // Get the deployableTo list from the SCF JSON file.
    for (let { deployableTo, type } of idf) {
      const sortedList = [...deployableTo];
      sortedList.sort((a, b) => {
        if (a.model < b.model) {
          return -1;
        }
        if (a.model > b.model) {
          return 1;
        }
        return 0;
      });
      if (type === testType) {
        return sortedList;
      }
    }
  }
  return deployableTo;
}

export function getWavelengthsListPerTest(testType) {
  const idf = getInstrumentDefinitionFile(productKeys.scf);
  const wavelengthsList = [];
  for (let { type } of idf) {
    if (
      type.includes(testTypesMap.powermeter) &&
      testType.includes(testTypesMap.powermeter)
    ) {
      wavelengthsList.push(type);
    } else if (
      type.includes(testTypesMap.opticalloss) &&
      testType.includes(testTypesMap.opticalloss)
    ) {
      wavelengthsList.push(type);
    }
  }
  return wavelengthsList;
}

export default function getProductInfo(product) {
  return {
    product: product,
    initialJob: getInitialJob(product),
    testTypes: getTestTypes(product),
    defaultConfigs: getDefaultConfigs(product),
    swagger: getSwagger(product),
    addMultiple: getAddMultiple(product),
    methodsProceduresPath: getMethodsProceduresPath(product),
    alwaysSetConfig: product !== "5800",
    ssAssets: getStrataSyncAssets(product),
    logoFilePath: getLogoFilePath(product),
    isOnx: getIsOnx(product),
    supportsLocationFileUpload: getLocationsFileUploadSupported(product),
    showDeployableTo: getShowDeployableTo(product),
  };
}

export function getStrataSyncAssets(product) {
  const assetMap = {
    5800: [
      "T-BERD/MTS 5800-100G",
      "T-BERD/MTS 5800 V2",
      "SmartClass 4800",
      "T-BERD/MTS 5882",
      "ONA-1000",
    ],
    4000: ["T-BERD/MTS 2000 V2", "T-BERD/MTS 4000 V2", "SmartOTDR"],
    ona800: ["ONA-800"],
    ca5g: ["CellAdvisor 5G"],
    onx580: ["OneExpert DSL"],
    onxcatv: ["OneExpert CATV"],
    nsc100: ["NSC"],
    onx220: ["OneExpert DSP"],
    scf: ["SmartClass Fiber"],
    rfv: ["RFVision"],
  };
  return assetMap[product];
}

function getInitialJob(product) {
  const initialJobs = {
    5800: initialJob5800,
    4000: initialJob4000,
    ona800: initialJob5800,
    ca5g: initialJob5800,
    onx580: initialJobOneExpertDSL,
    onxcatv: initialJobOneExpertCATV,
    nsc100: initialJobNSC100,
    onx220: initialJobOneExpertDSP,
    scf: initialJobSCF,
    rfv: initialJobRFVision,
  };
  return initialJobs[product];
}

function getTestTypes(product) {
  const testTypes = {
    5800: testTypes5800,
    4000: testTypes4000,
    ona800: testTypesOna800,
    ca5g: testTypesCA5G,
    onx580: testTypesOneExpertDSL,
    onxcatv: testTypesOneExpertCATV,
    nsc100: testTypesNSC100,
    onx220: testTypesOneExpertDSP,
    scf: getTestTypesFromIdf(product),
    rfv: testTypesRFVision,
  };
  return testTypes[product];
}

function getDefaultConfigs(product) {
  const defaultConfigs4000 = require("./defaultConfigs4000.json");
  const defaultConfigs5800 = require("./defaultConfigs5800.json");
  const defaultConfigsOna800 = require("./defaultConfigsOna800.json");
  const defaultConfigsCA5G = require("./defaultConfigsCA5G.json");
  const defaultConfigsONX = require("./defaultConfigsONX.json");
  const defaultConfigsRfv = require("./defaultConfigsRfv.json");
  const defaultConfigsMap = {
    4000: defaultConfigs4000,
    5800: defaultConfigs5800,
    ona800: defaultConfigsOna800,
    ca5g: defaultConfigsCA5G,
    onx580: defaultConfigsONX,
    onxcatv: defaultConfigsONX,
    nsc100: defaultConfigsONX,
    onx220: defaultConfigsONX,
    scf: getDefaultConfigsFromIdf(product),
    rfv: defaultConfigsRfv,
  };
  return defaultConfigsMap[product];
}

function getSwagger(product) {
  const swagger4000 = require("./swagger_4000.json");
  const swagger5800 = require("./swagger_5800.json");
  const swaggerOna800 = require("./swagger_ona800.json");
  const swaggerCa5g = require("./swagger_ca5g.json");
  const swaggerONX = require("./swaggerONX");
  const swaggerRfv = require("./swagger_rfv");
  const swaggerMap = {
    4000: swagger4000,
    5800: swagger5800,
    ona800: swaggerOna800,
    ca5g: swaggerCa5g,
    onx580: swaggerONX,
    onxcatv: swaggerONX,
    nsc100: swaggerONX,
    onx220: swaggerONX,
    scf: getSwaggerFromIdf(product),
    rfv: swaggerRfv,
  };
  return swaggerMap[product];
}

function getAddMultiple(product) {
  const addMultipleMap = {
    4000: {
      filterFunc: test4kcsv,
      availableTests: [
        "fiberInspection",
        "fiberContinuity",
        "OTDR",
        "fcompPro",
        "manualStep",
      ],
      csvTemplate: process.env.PUBLIC_URL + "/Label List Template.csv",
    },
    5800: false,
    ona800: false,
    ca5g: false,
    onx580: false,
    onxcatv: false,
    nsc100: false,
    onx220: false,
    scf: false,
    rfv: false,
  };
  return addMultipleMap[product];
}

function getMethodsProceduresPath(product) {
  const paths = {
    4000: null,
    5800: "/user/disk/job-manager/test-procedures/",
    ona800: "/user/job-manager/test-procedures/",
    ca5g: "/user/job-manager/test-procedures/",
    onx580: "",
    onxcatv: "",
    nsc100: "",
    onx220: "",
    scf: "",
    rfv: "",
  };
  return paths[product];
}

function getLogoFilePath(product) {
  const logoFilePaths = {
    5800: "/user/bert/images/",
    4000: "/user/disk/",
    ona800: "/user/bert/images/",
    ca5g: "/user/bert/images/",
    onx580: "/user/disk/",
    onxcatv: "/user/disk/",
    nsc100: "/user/disk/",
    scf: "/user/disk/",
    rfv: "/user/disk/",
  };
  return logoFilePaths[product];
}

function getLocationsFileUploadSupported(product) {
  const locationsFileUploadSupportedMap = {
    5800: false,
    ona800: false,
    ca5g: false,
    onx580: false,
    onxcatv: false,
    nsc100: false,
    onx220: false,
    scf: true,
    rfv: false,
  };
  return locationsFileUploadSupportedMap[product];
}

function getShowDeployableTo(product) {
  const showDeployableTo = {
    5800: false,
    ona800: false,
    ca5g: false,
    onx580: false,
    onxcatv: false,
    nsc100: false,
    onx220: false,
    scf: true,
    rfv: false,
  };
  return showDeployableTo[product];
}

function getIsOnx(product) {
  //This is still a bad way of testing whether a feature should be shown or not
  //Each element where this is used attributes, templates, etc, should have it's
  //own function
  return (
    product === "onx580" ||
    product === "onxcatv" ||
    product === "nsc100" ||
    product === "onx220" ||
    product === "rfv"
  );
}

function test4kcsv(currentValue) {
  return (
    currentValue["fiberNumber"] === "" || parseInt(currentValue["fiberNumber"]) //fiber number is an int or empty string
  );
}

export function findchemaByProductAndTest(product, testType) {
  return getSwagger(product)?.definitions[testType];
}

function getSwaggerFromIdf(product) {
  const testTypes = getTestTypesFromIdf(product);
  delete testTypes[testTypesMap.manualStep];
  let swagger = {};
  if (product === productKeys.scf) {
    swagger = require("../products/swaggerSCF.json");
    for (const testType of Object.keys(testTypes)) {
      const testCategory = testType.split(".")[0];
      swagger.definitions[testType] = JSON.parse(
        JSON.stringify(swagger.definitions[testCategory])
      );
      if (
        testType.split(".")[0] === testTypesMap.powermeter &&
        isWavelengthRange(testType)
      ) {
        swagger.definitions[
          testType
        ].properties.setup.items.wavelengthNm.readOnly = false;
      }
    }
  }
  return swagger;
}

export function getDefaultConfigsFromIdf(product) {
  const testTypes = getTestTypesFromIdf(product);
  delete testTypes[testTypesMap.manualStep];
  let defaultConfigs = {};
  if (product === productKeys.scf) {
    const scfConfig = require("../products/defaultConfigsScf.json");
    for (const testType of Object.keys(testTypes)) {
      if (!scfConfig[testType]) {
        const defaultConfig = scfConfig[testType.split(".")[0]];
        defaultConfigs[testType] = JSON.parse(JSON.stringify(defaultConfig));
        defaultConfigs[testType].testType = testType;
        if (
          [testTypesMap.powermeter, testTypesMap.opticalloss].includes(
            testType.split(".")[0]
          )
        ) {
          // update the setup array based on the test type with specific wavelengths
          const wavelengths = getWavelengthsFromTestType(testType);
          const defaultSetup = defaultConfigs[testType].setup[0];
          defaultConfigs[testType].setup = [];
          for (let wavelength of wavelengths) {
            defaultConfigs[testType].setup.push({
              ...defaultSetup,
              wavelengthNm: parseFloat(wavelength),
            });
          }
        }
      } else {
        defaultConfigs[testType] = scfConfig[testType];
      }
    }
  }
  return defaultConfigs;
}

export function getInstrumentDefinitionFile(product) {
  let idf = {};
  if (product === productKeys.scf) {
    idf = require("../products/scfInstrumentDefinition.json");
  }
  return idf;
}

function getTestTypesFromIdf(product) {
  const idf = getInstrumentDefinitionFile(product);
  let testTypes = {
    "Manual Step": {
      testType: "Manual Step",
      NotSupportProfile: true,
      launchableType: "manualStep",
      referenceInfo: [],
    },
  };
  if (product === productKeys.scf) {
    for (const testTypeObj of idf) {
      testTypes[testTypeObj.type] = {
        testType: testTypeObj.type,
        launchableType: testTypeObj.type,
        referenceInfo: [],
      };
    }
  }
  return testTypes;
}
