import { Component } from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { isSmartClassFiber } from "../products/productInfo.js";
import { FormattedMessage } from "react-intl";

class JobInfoInput extends Component {
  render() {
    var value = this.props.value;
    const name = this.props.field;
    const helpText = makeHelpText(name, value);
    let showAsterisk;
    if (isSmartClassFiber(this.props.productInfo.product)) {
      showAsterisk = <span className="text-danger h5">*</span>;
    }
    return (
      <FormGroup controlId={name}>
        <FormLabel>
          <FormattedMessage id={this.props.field} />
        </FormLabel>
        {showAsterisk}
        <JobInfoControl
          name={name}
          value={value}
          productInfo={this.props.productInfo}
          updateJobInfo={this.props.updateJobInfo}
        />
        <Form.Text className="text-muted">{helpText}</Form.Text>
      </FormGroup>
    );
  }
}

function JobInfoControl({ name, value, productInfo, updateJobInfo }) {
  function handleChange(e) {
    const name = e.target.name;
    var value = e.target.value;
    if (name === "logo" && value) {
      value = productInfo.logoFilePath + value;
    }
    if (e.target.type === "date" && name === "Date") {
      updateJobInfo(name, value, 0);
    } else if (e.target.type === "time" && name === "Date") {
      updateJobInfo(name, value, 1);
    } else {
      updateJobInfo(name, value);
    }
  }

  const logoFilePath = productInfo.logoFilePath;
  if (name === "logo" && value) {
    value = value.replace(logoFilePath, "");
  }

  if (name === "logo" && productInfo.product === "4000") {
    return (
      <FormControl
        as="select"
        value={value}
        onChange={handleChange}
        name={name}
      >
        <option value=""></option>
        <option value="logo.png">logo.png</option>
        <option value="logo.jpg">logo.jpg</option>
      </FormControl>
    );
  }
  if (name === "Date") {
    return (
      <Row>
        <Col lg={3}>
          <FormControl
            role="date"
            type="date"
            value={value[0]}
            onChange={handleChange}
            name={name}
          />
        </Col>
        <Col lg={1.5}>
          <FormControl
            role="time"
            type="time"
            value={value[1]}
            onChange={handleChange}
            name={name}
          />
        </Col>
      </Row>
    );
  }
  return (
    <FormControl
      type="text"
      value={value}
      onChange={handleChange}
      name={name}
    />
  );
}

function makeHelpText(name, value) {
  if (name === "logo") {
    return <FormattedMessage id="logoHelp" values={{ filepath: value }} />;
  } else {
    return "";
  }
}

export default JobInfoInput;
