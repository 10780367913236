import { testTypes } from "../constants/testTypes";
import { getSchemaVersion } from "../components/utilities.js";
import {
  findchemaByProductAndTest,
  getStrataSyncAssets,
  getDeployableToProducts,
} from "../products/productInfo";

function convertToOneExpertCDM(input, ssAssets) {
  let cdm = {
    workflow: {
      workOrderId: input.jobId,
      type: "viaviJob",
      date: makeJobManagerDate(input.Date),
      techInfo: {
        techId: input.technicianId,
      },
      jobManagerAttributes: makeONXAttributes(input.attributesPlan),
    },
    configuration: {},
    tests: makeONXTests(input.testPlan, false, ssAssets),
  };
  return cdm;
}

function convertToTamplateCDM(input, tamplateName, ssAssets) {
  let cdm = {
    workflow: {
      template: true,
      typeName: tamplateName,
      workOrderId: "",
      type: "viaviJob",
      date: makeJobManagerDate(input.Date),
      techInfo: {
        techId: input.technicianId,
      },
      jobManagerAttributes: makeONXAttributes(input.attributesPlan),
    },
    configuration: {},
    tests: makeONXTests(input.testPlan, true, ssAssets),
    cdmVersion: "2.1",
  };
  return cdm;
}

function convertToCDM(input, ssAssets) {
  return {
    workflow: {
      type: "viaviJob",
      workOrderId: input.jobNumber,
      techInfo: {
        techId: input.technicianId,
      },
      customerInfo: {
        company: input.customerName,
      },
      contractorId: input.contractorId || "",
      jobManagerAttributes: makeJobManagerAttributes(input),
      domainAttributes: {
        logoInfo: {
          path: input.logo,
        },
        logo: input.logo,
      },
    },
    tests: makeTests(input.testPlan, ssAssets),
  };
}

function makeJobManagerDate(input) {
  var moment = require("moment");
  /*const formattedDate = input.Date
    ? input.Date + ":00" + moment().format().slice(-6)
    : moment().format();*/
  // 2021-01-05T15:42:54+02:00
  //"2021-01-29" YYYY-MM-DD
  //"20:11"  hh:mm
  let formattedDate = moment().format();
  if (input[0] && input[1]) {
    formattedDate =
      input[0] + "T" + input[1] + ":00" + moment().format().slice(-6);
  } else if (input[0]) {
    formattedDate = input[0] + "T12:00" + moment().format().slice(-9);
  }
  return formattedDate;
}
function makeJobManagerAttributes(input) {
  let jobManagerAttributes = {
    testLocation: {
      label: "Location",
      value: input.testLocation,
    },
  };
  ["cableId", "testLocationB", "jobComments"].forEach((attribute) => {
    if (input[attribute]) {
      jobManagerAttributes[attribute] = {
        label: attribute,
        value: input[attribute],
      };
    }
  });
  return jobManagerAttributes;
}

function makeONXAttributes(attributesPlan) {
  let res = {};
  const values = Object.values(attributesPlan);

  values.forEach((item) => {
    if (item.jobAttributes.uniqueId === "" || item.jobAttributes.label === "")
      return;
    let a = item.jobAttributes.uniqueId;
    res[a] = {
      label: item.jobAttributes.label,
      value: item.jobAttributes.defaultValue,
      regExp:
        item.jobAttributes.validationType === "Regular expression"
          ? item.jobAttributes.validValueList
          : ".*",
      validValues:
        item.jobAttributes.validationType === "Valid Value List"
          ? item.jobAttributes.validValueList.split(",").map((item) => {
              return item.trim();
            })
          : [],
    };
  });
  return res;
}

function makeONXTests(testPlan, isTemplate, ssAssets) {
  let res = [];
  let templateObj = {};
  if (isTemplate) {
    templateObj.testPlanIndex = 0;
  }

  const values = Object.values(testPlan);

  values.forEach((item) => {
    let testItem;
    if (ssAssets.includes(getStrataSyncAssets("scf")[0])) {
      item.locations = item.locations.map((loc) => {
        return {
          location: loc.location.trim(),
        };
      });
    }
    const deployableTo = getDeployableToProducts(ssAssets, item.testType);
    if (isTemplate) {
      if (Array.isArray(item.locations) && item.locations.length) {
        testItem = {
          type: item.launchableType,
          label: ssAssets.includes(getStrataSyncAssets("scf")[0])
            ? item.testLabel
            : item.testType,
          configuration: makeONXConfiguration(item),
          proceduresUrl: item.procedures ? item.procedures : "",
        };
      } else {
        testItem = {
          type: item.launchableType,
          label: ssAssets.includes(getStrataSyncAssets("scf")[0])
            ? item.testLabel
            : item.testType,
          configuration: makeONXConfiguration(item),
          proceduresUrl: item.procedures ? item.procedures : "",
          testPlanIndex: templateObj.testPlanIndex++,
        };
      }
      if (item.locations) {
        testItem["testLocations"] = makeONXLocations(
          item.locations,
          true,
          templateObj
        );
      }
      if (getSchemaVersion(item.testType)) {
        testItem["schemaVersion"] = getSchemaVersion(item.testType);
      }
    } else {
      testItem = {
        type: item.launchableType,
        label:
          item.testType === "Manual Step" ||
          ssAssets.includes(getStrataSyncAssets("scf")[0])
            ? item.testLabel
            : item.testType,
        configuration: makeONXConfiguration(item),
        proceduresUrl: item.procedures ? item.procedures : "",
      };
      if (item.locations) {
        testItem["testLocations"] = makeONXLocations(item.locations, false, 0);
      }
      if (getSchemaVersion(item.testType)) {
        testItem["schemaVersion"] = getSchemaVersion(item.testType);
      }
    }
    if (
      item.testType === "Manual Step" &&
      ssAssets.includes(getStrataSyncAssets("rfv")[0])
    ) {
      testItem["required"] = false;
    }
    if (deployableTo.length > 0) {
      testItem["deployableTo"] = deployableTo;
    }
    res.push(testItem);
  });
  return res;
}

function makeONXConfiguration(test, product) {
  if (
    [
      testTypes.fiberInspection,
      testTypes.powermeter,
      testTypes.opticalloss,
      testTypes.truepon,
    ].includes(test.testType.split(".")[0])
  ) {
    return test.config;
  } else if (
    test.testType === "Antenna Alignment" ||
    test.testType === "Microwave Alignment"
  ) {
    const configSwagger = findchemaByProductAndTest("rfv", test.testType);
    if (configSwagger?.isNotFlatConfig) {
      let config = {};
      const swaggerProperties = configSwagger.properties;
      makeConfigBySwagger(config, swaggerProperties, test.config);
      return config;
    }
  } else if (test.testType === "Manual Step") {
    // some product doesn't provide Configuration Profile in manual step tests such as RFVision
    if (test.NotSupportProfile) return {};
  }
  return {
    profile: test.referenceInfo[0] ? test.referenceInfo[0].value : "",
  };
}

function makeONXLocations(locations, isTemplate, templateObj) {
  let res = [];

  locations.forEach((item) => {
    let a;
    if (isTemplate) {
      a = {
        label: item.location,
        testPlanIndex: templateObj.testPlanIndex++,
      };
    } else {
      a = {
        label: item.location,
      };
    }

    res.push(a);
  });
  return res;
}

function makeTests(testPlan, ssAssets) {
  var deployableTo = null;

  if (ssAssets) {
    deployableTo = ssAssets.map((asset) => {
      return {
        assetType: asset,
      };
    });
  }
  return testPlan.map((item) => {
    return {
      type: item.testType,
      label: item.testLabel || item.testType,
      configuration: makeConfiguration(item.config),
      attributes: makeAttributes(item.referenceInfo),
      testLocations: makeTestLocations(
        item.testType,
        item.referenceInfo,
        item.testLabel
      ),
      deployableTo: deployableTo,
      procedures: item.procedures,
    };
  });
}

function makeConfiguration(config) {
  if (config == null || config.testType !== "OTDR") {
    return config;
  }
  return {
    testType: testTypes.otdr,
    otdrSettings: {
      otdrTopology: config.otdrTopology,
      configFile: config.configFile,
      launchCable: config.launchCable,
      receiveCable: config.receiveCable,
      loopbackCable: config.loopbackCable,
      alarmThresholds: {
        thresholdSet: config.alarms,
      },
      setups: [],
      fiber: {},
    },
  };
}

function makeAttributes(referenceInfo) {
  return referenceInfo.reduce((res, item) => {
    res[item.key] = {
      label: item.key,
      value: item.value,
    };
    return res;
  }, {});
}

function makeTestLocations(testType, referenceInfo, testLabel) {
  let location = {
    label:
      referenceInfo
        .map((item) => {
          return item.value;
        })
        .filter(Boolean)
        .join(" ") || testType,
    attributes: {
      referenceInfo: referenceInfo.reduce((res, item) => {
        res[item.key] = item.value;
        return res;
      }, {}),
    },
  };
  if (testType === "manualStep") {
    location.attributes.subTypeInfo = { testLabel: testLabel };
  }
  return [location];
}

function makeConfigBySwagger(config, swagger, test) {
  for (const prop in swagger) {
    if (swagger[prop].type === "nestedObject") {
      makeConfigBySwagger((config[prop] = {}), swagger[prop], test);
    } else {
      if (swagger[prop] !== "nestedObject" && test[prop]) {
        switch (swagger[prop].type) {
          case "integer":
            config[prop] = parseInt(test[prop]);
            break;
          case "number":
            config[prop] = parseFloat(test[prop]);
            break;
          default:
            config[prop] = test[prop];
        }
      }
    }
  }
}

export { convertToOneExpertCDM, convertToTamplateCDM };
export default convertToCDM;
