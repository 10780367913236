import React from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  FormCheck,
  Form,
  Card,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { testTypes } from "../constants/testTypes";
import HelpModal from "./HelpModal.js";
import { useState, useEffect, useCallback } from "react";
import { TestSetupInput } from "./TestSetupInput";
import AddTestSetupButton from "./AddTestSetupButton";
import {
  isWavelengthRange,
  validateValue,
  cloneItem,
  HelpText,
  configValUI,
} from "./utilities";
import ObjectConfig from "./ObjectConfig";

function ConfigInput({
  testPlanIndex,
  label,
  value,
  updateConfig,
  updateNestedConfigToggleEdit,
  configSchema,
  testIndex,
  testType,
  addSetupToTest,
  removeSetupFromTest,
}) {
  const isRequired =
    configSchema && configSchema.properties[label]["valueRequired"];
  const isBool = configSchema && getIsBoolean(label, configSchema);
  if (isBool) {
    return (
      <FormGroup controlId={label + testIndex}>
        <CheckboxConfig
          value={value}
          label={label}
          updateConfig={updateConfig}
        />
        <FormLabel>
          <FormattedMessage id={label} />
        </FormLabel>
      </FormGroup>
    );
  } else {
    return (
      <FormGroup controlId={label + testIndex}>
        <FormLabel>
          <FormattedMessage id={label} />
        </FormLabel>
        {isRequired && (
          <HelpModal
            title={label}
            property={configSchema.properties[label]}
            valueRequired={isRequired}
          />
        )}
        <ConfigFormControl
          testPlanIndex={testPlanIndex}
          testIndex={testIndex}
          label={label}
          value={value}
          updateConfig={updateConfig}
          updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
          configSchema={configSchema}
          testType={testType}
          addSetupToTest={addSetupToTest}
          removeSetupFromTest={removeSetupFromTest}
        />
        <Form.Text className="text-muted">
          <HelpText label={label} value={value} />
        </Form.Text>
      </FormGroup>
    );
  }
}

function getBasePath(label, configSchema) {
  return configSchema.properties[label]["x-uiPathHint"];
}

function makeEnum(label, configSchema) {
  return configSchema.properties[label].enum;
}

function getIsNumber(label, configSchema) {
  return configSchema.properties[label].type === "number";
}

function getIsBoolean(label, configSchema) {
  return configSchema.properties[label].type === "boolean";
}

function getValidationRequired(label, configSchema) {
  return configSchema.properties[label]["validationRequired"];
}

function getIsArray(label, configSchema) {
  return configSchema.properties[label].type === "array";
}

function getIsMultiSelect(label, configSchema) {
  return configSchema.properties[label].items.type === "string";
}

function getIsObject(label, configSchema) {
  return configSchema.properties[label].type === "object";
}

function ConfigFormControl({
  testPlanIndex,
  testIndex,
  label,
  value,
  updateConfig,
  updateNestedConfigToggleEdit,
  configSchema,
  testType,
  addSetupToTest,
  removeSetupFromTest,
}) {
  const configEnum = makeEnum(label, configSchema);
  const basePath = getBasePath(label, configSchema);
  const isNumber = getIsNumber(label, configSchema);
  const isValidationRequired = getValidationRequired(label, configSchema);
  const isArray = getIsArray(label, configSchema);
  const isObject = getIsObject(label, configSchema);

  if (isValidationRequired) {
    return (
      <ValidatedConfig
        value={value}
        label={label}
        property={configSchema.properties[label]}
        updateConfig={updateConfig}
      />
    );
  } else if (configEnum) {
    return (
      <SelectConfig
        value={value}
        configEnum={configEnum}
        label={label}
        updateConfig={updateConfig}
      />
    );
  } else if (isNumber) {
    return (
      <NumberConfig
        value={value}
        step={0.1}
        precision={1}
        label={label}
        updateConfig={updateConfig}
      />
    );
  } else if (isObject) {
    return (
      <ObjectConfig
        testPlanIndex={testPlanIndex}
        testIndex={testIndex}
        value={value}
        label={label}
        updateConfig={updateConfig}
        updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
        configSchema={configSchema.properties[label]}
        testType={testType}
      />
    );
  } else if (isArray) {
    return (
      <ArrayConfig
        testPlanIndex={testPlanIndex}
        value={value}
        label={label}
        updateConfig={updateConfig}
        updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
        addSetupToTest={addSetupToTest}
        removeSetupFromTest={removeSetupFromTest}
        configSchema={configSchema}
        testType={testType}
      />
    );
  } else if (basePath) {
    return (
      <FilePathConfig
        value={value}
        label={label}
        basePath={basePath}
        updateConfig={updateConfig}
      />
    );
  } else if (testType === testTypes.otdr) {
    return (
      <OTDRCableConfig
        value={value}
        label={label}
        updateConfig={updateConfig}
      />
    );
  } else {
    return (
      <TextConfig value={value} label={label} updateConfig={updateConfig} />
    );
  }
}

function SelectConfig({ value, configEnum, label, updateConfig }) {
  function handleChange(e) {
    const value = e.target.value;
    updateConfig(label, value);
  }
  const options = configEnum.map((value, index) => (
    <option key={index} value={value}>
      {configValUI(value)}
    </option>
  ));

  return (
    <FormControl as="select" value={value} onChange={handleChange}>
      {options}
    </FormControl>
  );
}

function MultiSelectConfig({ value, label, configSchema, updateConfig }) {
  function handleChange(e) {
    const selected = [];
    let selectedOption = e.target.selectedOptions;

    for (let i = 0; i < selectedOption.length; i++) {
      selected.push(selectedOption.item(i).value);
    }
    updateConfig(label, selected);
  }

  const options = configSchema.properties[label].items.enum.map(
    (value, index) => (
      <option key={index} value={value}>
        {value}
      </option>
    )
  );
  return (
    <FormControl as="select" multiple value={value} onChange={handleChange}>
      {options}
    </FormControl>
  );
}

function FilePathConfig({ value, label, basePath, updateConfig }) {
  function handleChange(e) {
    var value = e.target.value;
    if (value) {
      value = basePath + value;
    }
    updateConfig(label, value);
  }

  value = value.replace(basePath, "");

  return <FormControl type="text" value={value} onChange={handleChange} />;
}

function TextConfig({ value, label, updateConfig }) {
  function handleChange(e) {
    let value = e.target.value;
    value = value ? value : "";
    updateConfig(label, value);
  }
  return <FormControl type="text" value={value} onChange={handleChange} />;
}

function NumberConfig({ value, label, updateConfig }) {
  function handleChange(e) {
    value = e.target.value;
    updateConfig(label, value);
  }
  return <FormControl type="number" value={value} onChange={handleChange} />;
}

function CheckboxConfig({ value, label, updateConfig }) {
  function handleChange(e) {
    value = e.target.checked;
    updateConfig(label, e.target.checked);
  }
  return (
    <FormCheck type="checkbox" inline checked={value} onChange={handleChange} />
  );
}

function ValidatedConfig({ value, label, updateConfig, property }) {
  const inputType = property.type;
  const min = property.minimum;
  const max = property.maximum;
  const pattern = property.pattern;
  const [isValid, setIsValid] = useState(null);
  const validateInput = useCallback(
    (input) => {
      const isInputValid = validateValue(input, inputType, min, max, pattern);
      return isInputValid;
    },
    [inputType, min, max, pattern]
  );

  useEffect(() => {
    const isValueValid = validateInput(value);
    setIsValid(isValueValid);
    updateConfig(label, value, isValueValid);
  }, [label, validateInput, value, updateConfig]);

  function handleChange(e) {
    const newValue = e.target.value;
    const isNewValueValid = validateInput(newValue);
    setIsValid(isNewValueValid);
    updateConfig(label, newValue, isNewValueValid);
  }
  return (
    <React.Fragment>
      <FormControl type="text" value={value} onChange={handleChange} />
      <Form.Text className={isValid ? "text-success" : "text-danger"}>
        <FormattedMessage id={isValid ? "Input is valid" : "Input not valid"} />
      </Form.Text>
    </React.Fragment>
  );
}

// Array of objects. Show one card per object.
// Each card will have the data contained in the object (assumes flat object).
function ArrayConfig({
  testPlanIndex,
  value,
  label,
  updateConfig,
  updateNestedConfigToggleEdit,
  addSetupToTest,
  removeSetupFromTest,
  configSchema,
  testType,
}) {
  if (getIsMultiSelect(label, configSchema)) {
    return (
      <MultiSelectConfig
        value={value}
        configSchema={configSchema}
        label={label}
        updateConfig={updateConfig}
      />
    );
  }
  const items = Object.values(value).map((setupFields, setupIndex) => {
    return (
      <TestSetupInput
        key={label + setupIndex}
        value={value}
        label={label}
        setupIndex={setupIndex}
        setupFields={setupFields}
        updateConfig={updateConfig}
        updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
        removeSetupFromTest={removeSetupFromTest}
        testPlanIndex={testPlanIndex}
        configSchema={configSchema.properties[label].items}
        testType={testType}
      />
    );
  });
  let addTestSetupButton;
  if (isWavelengthRange(testType)) {
    addTestSetupButton = (
      <AddTestSetupButton
        setup={cloneItem(value[0])}
        addSetupToTest={addSetupToTest}
        testPlanIndex={testPlanIndex}
      />
    );
  }
  return (
    <Card>
      <Card.Body className="App">
        {items}
        {addTestSetupButton}
      </Card.Body>
    </Card>
  );
}

function OTDRCableConfig({ value, label, updateConfig }) {
  function handleChange(e) {
    var value = e.target.value;
    if (value) {
      let floatValue = parseFloat(value);
      if (floatValue >= 0) {
        value = String(floatValue / 1000);
      } else {
        return;
      }
    }
    updateConfig(label, value);
  }

  if (value) {
    value = String(Math.round(parseFloat(value) * 1000));
  }
  return <FormControl type="text" value={value} onChange={handleChange} />;
}

export default ConfigInput;
