import React, { Component } from "react";

import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class AddTestAttributeButton extends Component {
  render() {
    return (
      <Button
        variant="outline-primary"
        id="add-attribute"
        onClick={this.props.addAttributesToAttributesPlan}
      >
        <FormattedMessage
          id="addattributebutton.message"
          defaultMessage="Add Job Attribute"
        />
      </Button>
    );
  }
}

export default AddTestAttributeButton;
