import { testTypes, trueponTestTypes } from "../constants/testTypes";
import ConfigInputs from "./ConfigInputs.js";
import { Fragment, useState } from "react";
import { SelectTest } from "./SelectOrDeleteTest.js";
import { getDefaultConfigsFromIdf, productKeys } from "../products/productInfo";

function ScfConfigInput({
  testPlanIndex,
  config,
  updateConfig,
  updateNestedConfigToggleEdit,
  configSchema,
  testIndex,
  addSetupToTest,
  removeSetupFromTest,
}) {
  const [selectedSubTest, setSelectedSubTest] = useState(
    trueponTestTypes.gponXgspon
  );

  function changeSubTest(e) {
    const subTest = e.target.value;
    let defaultConfigs = getDefaultConfigsFromIdf(productKeys.scf);
    let defaultTruePonConfig = defaultConfigs.truepon;
    setSelectedSubTest(subTest);
    if (subTest === trueponTestTypes.gpon) {
      updateConfig(trueponTestTypes.gpon, defaultTruePonConfig.gpon);
      // remove xgspon from the config
      updateConfig(trueponTestTypes.xgspon, null, null, true);
    } else if (subTest === trueponTestTypes.xgspon) {
      // remove gpon from the config
      updateConfig(trueponTestTypes.gpon, null, null, true);
      updateConfig(trueponTestTypes.xgspon, defaultTruePonConfig.xgspon);
    } else {
      updateConfig(trueponTestTypes.gpon, defaultTruePonConfig.gpon);
      updateConfig(trueponTestTypes.xgspon, defaultTruePonConfig.xgspon);
    }
  }

  if (config) {
    const options = [
      trueponTestTypes.gpon,
      trueponTestTypes.xgspon,
      trueponTestTypes.gponXgspon,
    ];
    if (config.testType === testTypes.truepon) {
      return (
        <Fragment>
          <SelectTest
            index={testPlanIndex}
            selectValue={selectedSubTest}
            options={options}
            changeTestType={changeSubTest}
          />
          <ConfigInputs
            testPlanIndex={testPlanIndex}
            config={config}
            updateConfig={updateConfig}
            updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
            configSchema={configSchema}
            testIndex={testIndex}
            addSetupToTest={addSetupToTest}
            removeSetupFromTest={removeSetupFromTest}
          />
        </Fragment>
      );
    } else {
      return (
        <ConfigInputs
          testPlanIndex={testPlanIndex}
          config={config}
          updateConfig={updateConfig}
          updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
          configSchema={configSchema}
          testIndex={testIndex}
          addSetupToTest={addSetupToTest}
          removeSetupFromTest={removeSetupFromTest}
        />
      );
    }
  } else {
    return null;
  }
}

export default ScfConfigInput;
