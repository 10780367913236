import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { getWavelengthsListPerTest } from "../products/productInfo";

export function SelectWavelength({ index, selectedTest, changeTestType }) {
  const intl = useIntl();
  const optionList = makeOptionList(intl, selectedTest, selectedTest);
  return (
    <FormGroup controlId={"select-wavelength-" + index}>
      <FormLabel>
        <FormattedMessage
          id="selectWavelength.message"
          defaultMessage="Select Wavelength"
        />
      </FormLabel>
      <FormControl
        as="select"
        value={selectedTest}
        onChange={changeTestType}
        autoFocus={true}
      >
        {optionList}
      </FormControl>
    </FormGroup>
  );
}

function makeOptionList(intl, selectedTest) {
  const wavelengths = getWavelengthsListPerTest(selectedTest);
  const optionList = wavelengths.map((wavelength, index) => (
    <option key={index} value={`${wavelength}`}>
      {intl.formatMessage(
        {
          id: wavelength.slice(selectedTest.indexOf(".") + 1),
          defaultMessage: "{currWavelength} nm",
        },
        {
          currWavelength: wavelength.slice(selectedTest.indexOf(".") + 1),
        }
      )}
    </option>
  ));
  return optionList;
}
