import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function HelpModal({ title, property, valueRequired }) {
  const [show, setShow] = useState(false);
  const min = property.minimum;
  const max = property.maximum;
  const desc = property.description;
  const typeDesc = property.typeDescription;
  const minValue = typeof min !== "undefined" ? "Minimum: " + min : "";
  const maxValue = typeof max !== "undefined" ? "Maximum: " + max : "";
  let showAsterisk;
  let showRequired;
  if (valueRequired) {
    showAsterisk = "*";
    showRequired = (
      <ListGroupItem className="text-danger h5">* Required</ListGroupItem>
    );
  }
  return (
    <React.Fragment>
      <span className="text-danger h5">
        {showAsterisk}
        <Button
          variant="link"
          size="sm"
          onClick={() => setShow(true)}
          aria-label="Component Help Button"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
        <Modal size="lg" show={show} onHide={() => setShow()}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id={title} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {showRequired}
              <ListGroupItem>{desc}</ListGroupItem>
              <ListGroupItem>{typeDesc}</ListGroupItem>
              {minValue.length > 0 && <ListGroupItem>{minValue}</ListGroupItem>}
              {maxValue.length > 0 && <ListGroupItem>{maxValue}</ListGroupItem>}
            </ListGroup>
          </Modal.Body>
        </Modal>
      </span>
    </React.Fragment>
  );
}
export default HelpModal;
