const testTypesOneExpertDSL = {
  "OneCheck DSL": {
    testType: "OneCheck DSL",
    launchableType: "oneCheckDSL",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Single xDSL": {
    testType: "Single xDSL",
    launchableType: "xdsl",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Bonded xDSL": {
    testType: "Bonded xDSL",
    launchableType: "xdslBonded",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "SmartID Coax Check": {
    testType: "SmartID Coax Check",
    launchableType: "coaxCheck",
    referenceInfo: [],
  },
  Ethernet: {
    testType: "Ethernet",
    launchableType: "ethernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Fiber Inspection": {
    testType: "Fiber Inspection",
    launchableType: "fiberInspection",
    referenceInfo: [],
  },
  "SmartID OneCheck Coax": {
    testType: "SmartID OneCheck Coax",
    launchableType: "oneCheckCoax",
    referenceInfo: [],
  },
  "OneCheck Ethernet": {
    testType: "OneCheck Ethernet",
    launchableType: "oneCheckEthernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck Fiber": {
    testType: "OneCheck Fiber",
    launchableType: "oneCheckFiber",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Optical Power Meter": {
    testType: "Optical Power Meter",
    launchableType: "powerMeter",
    referenceInfo: [],
  },
  "WiFi Scan": {
    testType: "WiFi Scan",
    launchableType: "wifiScan",
    referenceInfo: [],
  },
  "OneCheck WiFi": {
    testType: "OneCheck WiFi",
    launchableType: "oneCheckWiFi",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "WiFi Site Assessment": {
    testType: "WiFi Site Assessment",
    launchableType: "wfaSiteAssessment",
    referenceInfo: [],
  },
  "WiFi Smart Channel Wizard": {
    testType: "WiFi Smart Channel Wizard",
    launchableType: "wfaSmartChannelWizard",
    referenceInfo: [],
  },
  "Balance Sweep": {
    testType: "Balance Sweep",
    launchableType: "balanceSweep",
    referenceInfo: [],
  },
  Current: {
    testType: "Current",
    launchableType: "current",
    referenceInfo: [],
  },
  FEXT: {
    testType: "fext",
    launchableType: "wfaSmartChannelWizard",
    referenceInfo: [],
  },
  Impedance: {
    testType: "Impedance",
    launchableType: "impedance",
    referenceInfo: [],
  },
  "Impulse Noise": {
    testType: "Impulse Noise",
    launchableType: "impulseNoise",
    referenceInfo: [],
  },
  "Impulse Noise Capture": {
    testType: "Impulse Noise Capture",
    launchableType: "impulseNoiseCapture",
    referenceInfo: [],
  },
  "Load Coil": {
    testType: "Load Coil",
    launchableType: "loadCoil",
    referenceInfo: [],
  },
  "Longitudinal Balance": {
    testType: "Longitudinal Balance",
    launchableType: "longitudinalBalance",
    referenceInfo: [],
  },
  "Loss Sweep": {
    testType: "Loss Sweep",
    launchableType: "lossSweep",
    referenceInfo: [],
  },
  Noise: {
    testType: "Noise",
    launchableType: "noise",
    referenceInfo: [],
  },
  "OneCheck Copper": {
    testType: "OneCheck Copper",
    launchableType: "oneCheckCopper",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck POTS": {
    testType: "OneCheck POTS",
    launchableType: "oneCheckPOTS",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  Opens: {
    testType: "Opens",
    launchableType: "opens",
    referenceInfo: [],
  },
  "Power Influence and Noise": {
    testType: "Power Influence and Noise",
    launchableType: "powerInfluenceNoise",
    referenceInfo: [],
  },
  Resistance: {
    testType: "Resistance",
    launchableType: "resistance",
    referenceInfo: [],
  },
  "Return Loss": {
    testType: "Return Loss",
    launchableType: "returnLoss",
    referenceInfo: [],
  },
  "RX Tones": {
    testType: "RX Tones",
    launchableType: "rxTones",
    referenceInfo: [],
  },
  RFL: {
    testType: "RFL",
    launchableType: "rfl",
    referenceInfo: [],
  },
  "Simultaneous Tones": {
    testType: "Simultaneous Tones",
    launchableType: "simultaneousTones",
    referenceInfo: [],
  },
  Spectral: {
    testType: "Spectral",
    launchableType: "spectral",
    referenceInfo: [],
  },
  "Sweep NEXT": {
    testType: "Sweep NEXT",
    launchableType: "sweepNEXT",
    referenceInfo: [],
  },
  TDR: {
    testType: "TDR",
    launchableType: "tdr",
    referenceInfo: [],
  },
  Voltage: {
    testType: "Voltage",
    launchableType: "voltage",
    referenceInfo: [],
  },
  "Manual Step": {
    testType: "Manual Step",
    launchableType: "manualStep",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
};
export default testTypesOneExpertDSL;
