const SS_URLS = {
  test: "https://test.stratasync.viavisolutions.com",
  stage: "https://stage.stratasync.viavisolutions.com",
  prod_us: "https://stratasync.viavisolutions.com",
  prod_eu: "https://eu.stratasync.viavisolutions.com",
};
const CLIENT_ID = "6fdfc808-b167-405b-8fb7-408f60b592ec&state=ksdkifkfkk12321";

function getLoginHref(ssServer) {
  return (
    SS_URLS[ssServer] +
    "/oauth/authorize?response_type=token&client_id=" +
    CLIENT_ID +
    "&redirect_uri=" +
    window.location.href
  );
}

async function getMyUser(ssServer, accessToken) {
  const url = SS_URLS[ssServer];
  const response = await fetch(url + "/api/v1/users/myuser", {
    headers: new Headers({
      Authorization: "Bearer " + accessToken,
    }),
  })
    .then((response) => response.json())
    .catch(function (error) {
      return {
        result: {
          firstName: " ",
          lastName: " ",
          accountId: " ",
          techId: " ",
        },
      };
    });
  return response.result;
}

async function getInstrumentsFromStratasync(ssServer, accessToken, assets) {
  const url = SS_URLS[ssServer];
  const headers = new Headers({
    Authorization: "Bearer " + accessToken,
  });
  function searchAssetType(assetType) {
    return fetch(url + "/api/v1/assets/search?assetType=" + assetType, {
      headers: headers,
    }).then((response) => response.json());
  }
  try {
    const responses = await Promise.all(assets.map(searchAssetType));
    return responses;
  } catch (error) {
    console.log(error);
    return [];
  }
}

async function sendJobToStrataSync(ssServer, accessToken, commonDataModel) {
  const url = SS_URLS[ssServer];
  const requestBody = {
    cdmVersion: "2.1",
    cdm: commonDataModel,
  };
  var headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  });
  const response = await fetch(url + "/api/v2/workflow/", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .catch(function (error) {
      console.log(error);
    });
  if (response.type === "failure") {
    console.error(response.message);
  }
  return response.httpStatus === 200;
}

async function searchWorkflows(ssServer, accessToken) {
  const url = SS_URLS[ssServer];
  var headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  });
  const response = await fetch(url + "/api/v2/workflow/search/", {
    method: "Post",
    headers: headers,
    body: JSON.stringify({ "workflow.date": "2020-09-30T20:48:10.468Z" }),
  });
  if (response.ok) {
    const json = await response.json();
    return json;
  }
}

export async function checkWorkflowLicense(ssServer, accessToken) {
  const url = SS_URLS[ssServer];
  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  });
  let response = await fetch(url + "/api/v2/workflow/checklicense", {
    headers: headers,
  });
  return response.ok;
}

export {
  getLoginHref,
  getMyUser,
  sendJobToStrataSync,
  getInstrumentsFromStratasync,
  searchWorkflows,
};
