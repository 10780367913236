const testTypes5800 = {
  "Fiber Inspection": {
    testType: "Fiber Inspection",
    referenceInfo: [
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Connector",
        value: "",
      },
    ],
  },
  OTDR: {
    testType: "OTDR",
    referenceInfo: [],
  },
  opticalPower: {
    testType: "opticalPower",
    referenceInfo: [],
  },
  OSA: {
    testType: "OSA",
    referenceInfo: [],
  },
  "CPRI Check": {
    testType: "CPRI Check",
    referenceInfo: [
      {
        key: "Radio",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
    ],
  },
  "RFC 2544": {
    testType: "RFC 2544",
    referenceInfo: [],
  },
  QuickCheck: {
    testType: "QuickCheck",
    referenceInfo: [],
  },
  "Y.1564 SAMComplete": {
    testType: "Y.1564 SAMComplete",
    referenceInfo: [],
  },
  "RFC 6349 TrueSpeed": {
    testType: "RFC 6349 TrueSpeed",
    referenceInfo: [],
  },
  "OTN Check": {
    testType: "OTN Check",
    referenceInfo: [],
  },
  "PTP Check": {
    testType: "PTP Check",
    referenceInfo: [],
  },
  "Fibre Channel": {
    testType: "Fibre Channel",
    referenceInfo: [],
  },
  "Optics Self-Test": {
    testType: "Optics Self-Test",
    referenceInfo: [],
  },
  "Cable Test": {
    testType: "Cable Test",
    referenceInfo: [],
  },
  "Cable Diagnostics": {
    testType: "Cable Diagnostics",
    referenceInfo: [],
  },
  "5G NR Discovery": {
    testType: "5G NR Discovery",
    referenceInfo: [],
  },
  CPRI: {
    testType: "CPRI",
    referenceInfo: [],
  },
  SDH: {
    testType: "SDH",
    referenceInfo: [],
  },
  SONET: {
    testType: "SONET",
    referenceInfo: [],
  },
  Ethernet: {
    testType: "Ethernet",
    referenceInfo: [],
  },
  OTN: {
    testType: "OTN",
    referenceInfo: [],
  },
  "PTP/1588": {
    testType: "PTP/1588",
    referenceInfo: [],
  },
  "Dark Fiber Qual": {
    testType: "Dark Fiber Qual",
    referenceInfo: [],
  },
  E1: {
    testType: "E1",
    referenceInfo: [],
  },
  DS1: {
    testType: "DS1",
    referenceInfo: [],
  },
  VoIP: {
    testType: "VoIP",
    referenceInfo: [],
  },
  "1PPS": {
    testType: "1PPS",
    referenceInfo: [],
  },
  Signature: {
    testType: "Signature",
    referenceInfo: [],
  },
  Picture: {
    testType: "Picture",
    referenceInfo: [],
  },
};

export default testTypes5800;
