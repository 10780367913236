import { getInstrumentsFromStratasync } from "./api.js";

async function getTechnicianList(ssServer, accessToken, ssAssets) {
  const responses = await getInstrumentsFromStratasync(
    ssServer,
    accessToken,
    ssAssets
  );
  const results = concatenateResults(responses);
  return mapAndFilterResults(results);
}

function concatenateResults(responses) {
  return [].concat.apply(
    [],
    Array.from(responses, (response) => response.result || [])
  );
}

function mapAndFilterResults(results) {
  const unfilterdList = results.map((item) =>
    JSON.stringify({ techFullName: item.techFullName, techId: item.techId })
  );
  const unique = [...new Set(unfilterdList)].map((item) => JSON.parse(item));
  const technicianList = unique.filter(
    (item) => item.techFullName && item.techId
  );
  return technicianList;
}

export { getTechnicianList };
