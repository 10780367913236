import { useIntl } from "react-intl";

export default function ReferenceInfoDisplay({ referenceInfo }) {
  const intl = useIntl();
  return referenceInfo.map((item, index) => (
    <span key={index}>
      {intl.formatMessage({ id: item.key })}: <strong>{item.value} </strong>
    </span>
  ));
}
