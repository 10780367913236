import { Fragment, Component, useState } from "react";
import {
  isSmartClassFiber,
  getDeployableToProducts,
  productKeys,
  displayLabels,
} from "../products/productInfo";

import {
  Card,
  Row,
  Col,
  FormCheck,
  Button,
  Modal,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import ReferenceInfoInput from "./ReferenceInfoInput.js";
import {
  initPlannedTest,
  testOptions,
  makeConfig,
  updateDynamicConfig,
} from "./utilities.js";
import ConfigInputs from "./ConfigInputs.js";
import {
  SelectTest,
  DeleteTestButton,
  ToggleEditButton,
} from "./SelectOrDeleteTest.js";
import { SelectScfTest } from "./SelectScfTest";
import { SelectWavelength } from "./SelectWavelength";
import MethodsProceduresInput from "./MethodsProceduresInput";
import TestLabelInput from "./TestLabelInput.js";
import AddLocationButton from "./AddLocationButton.js";
import { testTypes } from "../constants/testTypes";
import ScfConfigInput from "./ScfConfigInput";

export class PlannedTestInput extends Component {
  constructor(props) {
    super(props);
    this.changeLocationItem = this.changeLocationItem.bind(this);
    this.changeTestType = this.changeTestType.bind(this);
    this.addLocationToplannedTest = this.addLocationToplannedTest.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.updateTestLabel = this.updateTestLabel.bind(this);
    this.updateReferenceInfo = this.updateReferenceInfo.bind(this);
    this.toggelSetConfig = this.toggelSetConfig.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.getVisibleFields = this.getVisibleFields.bind(this);
    this.updateMethodsProceduresFile =
      this.updateMethodsProceduresFile.bind(this);
  }

  changeTestType(e) {
    const index = this.props.index;
    const testSelection = e.target.value;
    const plannedTest = initPlannedTest(this.props.productInfo, testSelection);
    this.props.updateTestPlanItem(index, plannedTest);
  }
  changeLocationItem(e) {
    const index = this.props.index;
    const locationIndex = e.target.id;
    const value = e.target.value;
    var plannedTest = this.props.plannedTest;
    plannedTest.locations[parseInt(locationIndex)].location = value;
    this.props.updateTestPlanItem(index, plannedTest);
  }
  addLocationToplannedTest() {
    const testPlanIndex = this.props.index;
    var plannedTest = this.props.plannedTest;
    var location = "";
    plannedTest.locations.push({ location: location });
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }
  deleteLocation(index) {
    const testPlanIndex = this.props.index;
    var plannedTest = this.props.plannedTest;
    plannedTest.locations.splice(index, 1);
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }

  updateTestLabel(label) {
    const testPlanIndex = this.props.index;
    var plannedTest = this.props.plannedTest;
    plannedTest.testLabel = label;
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }

  updateReferenceInfo(referenceInfoArray) {
    const testPlanIndex = this.props.index;
    const plannedTest = {
      ...this.props.plannedTest,
      referenceInfo: referenceInfoArray,
    };
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }

  toggelSetConfig(config) {
    const testPlanIndex = this.props.index;
    const plannedTest = Object.assign({}, this.props.plannedTest, {
      config: config,
    });
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }

  updateConfig(label, value, isConfigValid, removeConfig) {
    const testPlanIndex = this.props.index;
    var plannedTest = this.props.plannedTest;
    plannedTest.config[label] = value;

    if (removeConfig) {
      delete plannedTest.config[label];
    }
    // only for the inputs required validation
    if (plannedTest.configValidation && typeof isConfigValid !== "undefined") {
      plannedTest.configValidation[label] = isConfigValid;
    }
    // only for the inputs whose value will directly impact other config properties in the test
    const isDynamicConfig =
      this.props.productInfo.swagger.definitions[plannedTest.testType]
        .isDynamicConfig;
    if (isDynamicConfig) {
      plannedTest = updateDynamicConfig(plannedTest, label, value);
    }
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }

  updateMethodsProceduresFile(value) {
    const testPlanIndex = this.props.index;
    var plannedTest = this.props.plannedTest;
    plannedTest.procedures = value;
    this.props.updateTestPlanItem(testPlanIndex, plannedTest);
  }

  getVisibleFields(product, config, testType) {
    let visibleConfig = JSON.parse(JSON.stringify(config));
    if (product === productKeys.scf) {
      if (testTypes.powermeter.includes(testType.split(".")[0])) {
        const tempSetup = visibleConfig.setup;
        for (let idx = 0; idx < tempSetup.length; idx++) {
          if (visibleConfig.measurementMode === "absolute") {
            visibleConfig.setup[idx] = {
              lowerThresholddBm: tempSetup[idx].lowerThresholddBm,
              wavelengthNm: tempSetup[idx].wavelengthNm,
            };
          } else {
            visibleConfig.setup[idx] = {
              lowerThresholddB: tempSetup[idx].lowerThresholddB,
              wavelengthNm: tempSetup[idx].wavelengthNm,
            };
          }
        }
      } else if (testTypes.fiberInspection.includes(testType.split(".")[0])) {
        if (visibleConfig.inspectionType === "MPO") {
          visibleConfig = Object.assign(
            { connectorDefinitionName: visibleConfig.connectorDefinitionName },
            visibleConfig
          );
        } else {
          delete visibleConfig.connectorDefinitionName;
        }
      }
    }
    return visibleConfig;
  }

  render() {
    function flatConfigSchema(properties, tempProperties) {
      for (const prop in properties) {
        if (properties[prop].type === "nestedObject") {
          flatConfigSchema(properties[prop], tempProperties);
        } else {
          tempProperties[prop] = properties[prop];
        }
      }
    }
    const { referenceInfo, testType, config, testLabel, procedures } =
      this.props.plannedTest;
    const configSchema = Object.assign(
      {},
      this.props.productInfo.swagger.definitions[testType]
    );
    if (configSchema.isNotFlatConfig) {
      let flatConfigSchemaProperties = {};
      flatConfigSchema(configSchema.properties, flatConfigSchemaProperties);
      configSchema.properties = flatConfigSchemaProperties;
    }

    const visibleConfig = this.getVisibleFields(
      this.props.productInfo.product,
      config,
      testType
    );
    const options = testOptions(this.props.productInfo.testTypes);
    let testLocationList;
    if (
      this.props.productInfo.isOnx &&
      this.props.productInfo.product !== productKeys.rfv
    ) {
      testLocationList = this.props.plannedTest.locations.map(
        (testLocationItem, index) => (
          <Row key={index}>
            <Col lg={10}>
              <FormGroup controlId={index}>
                <FormLabel>
                  <FormattedMessage id={"Test Location #" + (index + 1)} />
                </FormLabel>
                <FormControl
                  type="text"
                  value={testLocationItem.location}
                  onChange={this.changeLocationItem}
                />
              </FormGroup>
            </Col>
            <Col
              style={{
                display: "flex",
                flex: 0.01,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteTestButton
                deleteTest={this.deleteLocation}
                index={index}
              />
            </Col>
          </Row>
        )
      );
    }
    const selectTestAndLabel =
      ["Manual Step", "manualStep"].indexOf(testType) > -1 ? (
        this.props.productInfo.isOnx ? (
          <Col>
            <SelectTest
              index={this.props.index}
              selectValue={testType}
              options={options}
              changeTestType={this.changeTestType}
            />
            <TestLabelInput
              label={testLabel}
              updateTestLabel={this.updateTestLabel}
            />
          </Col>
        ) : (
          <Fragment>
            <Col>
              <SelectTest
                index={this.props.index}
                selectValue={testType}
                options={options}
                changeTestType={this.changeTestType}
              />
            </Col>
            <Col xs={{ span: 12, order: 12 }} md={{ span: 6, order: 0 }}>
              <TestLabelInput
                label={testLabel}
                updateTestLabel={this.updateTestLabel}
              />
            </Col>
          </Fragment>
        )
      ) : (
        <Col>
          <SelectTest
            index={this.props.index}
            selectValue={testType}
            options={options}
            changeTestType={this.changeTestType}
          />
        </Col>
      );
    if (this.props.productInfo.isOnx) {
      return (
        <Card body>
          <Row>
            {selectTestAndLabel}
            <Col xs={3} sm={2} lg={1} style={{ textAlign: "left" }}>
              <ToggleEditButton
                toggleEdit={this.props.toggleEdit}
                index={this.props.index}
              />
              <DeleteTestButton
                deleteTest={this.props.deleteTest}
                index={this.props.index}
              />
            </Col>
          </Row>
          <ReferenceInfoList
            testType={testType}
            referenceInfo={referenceInfo}
            updateReferenceInfo={this.updateReferenceInfo}
            isOnx={this.props.productInfo.isOnx}
            testIndex={this.props.index}
          />
          {this.props.productInfo.product !== "rfv" ? (
            <div>
              <Row>
                <Col lg={12}>{testLocationList}</Col>
              </Row>
              <AddLocationButton
                addLocationToplannedTest={this.addLocationToplannedTest}
              />
            </div>
          ) : (
            <ConfigInputs
              testPlanIndex={this.props.index}
              config={config}
              updateConfig={this.updateConfig}
              configSchema={configSchema}
              testIndex={this.props.index}
              addSetupToTest={this.props.addSetupToTest}
              removeSetupFromTest={this.props.removeSetupFromTest}
            />
          )}
          <Row>
            <Col lg={12}>
              <MethodsProceduresInput
                productInfo={this.props.productInfo}
                value={procedures || ""}
                updateMethodsProceduresFile={this.updateMethodsProceduresFile}
                testIndex={this.props.index}
              />
            </Col>
          </Row>
        </Card>
      );
    } else if (isSmartClassFiber(this.props.productInfo.product)) {
      let selectWavelength;
      const selectScfTest =
        ["Manual Step", "manualStep"].indexOf(testType) > -1 ? (
          <Fragment>
            <Col>
              <SelectScfTest
                index={this.props.index}
                selectValue={testType}
                options={options}
                changeTestType={this.changeTestType}
                showDeployableTo={this.props.productInfo.showDeployableTo}
                instrumentsList={getDeployableToProducts(
                  [displayLabels.scf],
                  testType
                ).map((val) => val.model)}
              />
            </Col>
            <Col xs={{ span: 12, order: 12 }} md={{ span: 6, order: 0 }}>
              <TestLabelInput
                label={testLabel}
                updateTestLabel={this.updateTestLabel}
              />
            </Col>
          </Fragment>
        ) : (
          <Col>
            <SelectScfTest
              index={this.props.index}
              selectValue={testType}
              options={options}
              changeTestType={this.changeTestType}
              showDeployableTo={this.props.productInfo.showDeployableTo}
              instrumentsList={getDeployableToProducts(
                [displayLabels.scf],
                testType
              ).map((val) => val.model)}
            />
          </Col>
        );
      if (
        [testTypes.powermeter, testTypes.opticalloss].includes(
          testType.split(".")[0]
        )
      ) {
        selectWavelength = (
          <Col>
            <SelectWavelength
              index={this.props.index}
              selectedTest={testType}
              options={options}
              changeTestType={this.changeTestType}
            />
          </Col>
        );
      }
      return (
        <Card body>
          <Row>
            {selectScfTest}
            {selectWavelength}
            <Col xs={3} sm={2} lg={1} style={{ textAlign: "left" }}>
              <ToggleEditButton
                toggleEdit={this.props.toggleEdit}
                index={this.props.index}
              />
              <DeleteTestButton
                deleteTest={this.props.deleteTest}
                index={this.props.index}
              />
            </Col>
          </Row>
          <ScfConfigInput
            testPlanIndex={this.props.index}
            config={visibleConfig}
            updateConfig={this.updateConfig}
            updateNestedConfigToggleEdit={
              this.props.updateNestedConfigToggleEdit
            }
            configSchema={configSchema}
            testIndex={this.props.index}
            addSetupToTest={this.props.addSetupToTest}
            removeSetupFromTest={this.props.removeSetupFromTest}
          />
        </Card>
      );
    } else {
      return (
        <Card body>
          <Row>
            {selectTestAndLabel}
            <Col xs={3} sm={2} lg={1} style={{ textAlign: "left" }}>
              <ToggleEditButton
                toggleEdit={this.props.toggleEdit}
                index={this.props.index}
              />
              <DeleteTestButton
                deleteTest={this.props.deleteTest}
                index={this.props.index}
              />
            </Col>
          </Row>
          <ReferenceInfoList
            testType={testType}
            referenceInfo={referenceInfo}
            updateReferenceInfo={this.updateReferenceInfo}
            isOnx={this.props.productInfo.isOnx}
            testIndex={this.props.index}
          />
          <SetConfigCheckBox
            productInfo={this.props.productInfo}
            index={this.props.index}
            testType={testType}
            config={visibleConfig}
            toggelSetConfig={this.toggelSetConfig}
          />
          <ConfigInputs
            testPlanIndex={this.props.index}
            config={visibleConfig}
            updateConfig={this.updateConfig}
            configSchema={configSchema}
            testIndex={this.props.index}
            addSetupToTest={this.props.addSetupToTest}
            removeSetupFromTest={this.props.removeSetupFromTest}
          />
          <MethodsProceduresInput
            productInfo={this.props.productInfo}
            value={procedures || ""}
            updateMethodsProceduresFile={this.updateMethodsProceduresFile}
            testIndex={this.props.index}
          />
        </Card>
      );
    }
  }
}

export class SetConfigCheckBox extends Component {
  constructor(props) {
    super(props);
    this.setConfig = this.setConfig.bind(this);
  }

  setConfig(e) {
    if (e.target.checked) {
      this.props.toggelSetConfig(
        makeConfig(this.props.testType, this.props.productInfo)
      );
    } else {
      this.props.toggelSetConfig(null);
    }
  }

  render() {
    if (this.props.productInfo.alwaysSetConfig) {
      return null;
    }
    const checked = shouldBeChecked(this.props.config);
    if (makeConfig(this.props.testType, this.props.productInfo)) {
      return (
        <FormCheck
          type="switch"
          id={"set-config-" + this.props.index}
          checked={checked}
          label={
            <FormattedMessage
              id="setConfigCheckBox.message"
              defaultMessage="Set Configuration"
            />
          }
          onChange={this.setConfig}
        />
      );
    } else {
      return null;
    }
  }
}

function ReferenceInfoList({
  testType,
  referenceInfo,
  updateReferenceInfo,
  isOnx,
  testIndex,
}) {
  function updateReferenceInfoItem(referenceInfoIndex, referenceInfoItem) {
    referenceInfo[referenceInfoIndex] = referenceInfoItem;
    updateReferenceInfo(referenceInfo);
  }

  function addReferenceInfoItem(key) {
    updateReferenceInfo([...referenceInfo, { key: key, value: "" }]);
  }

  function deleteReferenceInfoItem(index) {
    referenceInfo.splice(index, 1);
    updateReferenceInfo([...referenceInfo]);
  }

  let allowEditLabels = testType === "manualStep" && isOnx !== true;
  let atMaxItems = referenceInfo.length < 2;
  let columnSize = referenceInfo.length > 3 ? 6 : 12;

  var referenceInfoList = referenceInfo.map((referenceInfoInput, index) => (
    <Col lg={columnSize} key={index}>
      <ReferenceInfoInput
        _key={referenceInfoInput["key"]}
        value={referenceInfoInput["value"]}
        key={index}
        index={index}
        updateReferenceInfo={updateReferenceInfoItem}
        showDelete={allowEditLabels}
        deleteReferenceInfoItem={deleteReferenceInfoItem}
        testIndex={testIndex}
      />
    </Col>
  ));
  return (
    <Row>
      {referenceInfoList}
      {allowEditLabels && atMaxItems && (
        <Col lg={columnSize}>
          <AddReferenceInfoButton addReferenceInfoItem={addReferenceInfoItem} />
        </Col>
      )}
    </Row>
  );
}

function AddReferenceInfoButton({ addReferenceInfoItem }) {
  let [show, setShow] = useState(false);
  let [key, setKey] = useState("");

  function handleClose() {
    setKey("");
    setShow(false);
  }

  function handleSubmit() {
    addReferenceInfoItem(key);
    handleClose();
  }

  return (
    <>
      <Button
        size="sm"
        variant="link"
        style={{
          border: "none",
          padding: 0,
        }}
        aria-label="Add Reference Info"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter New Label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl
              type="text"
              value={key}
              aria-label="New Label Input"
              onChange={(e) => setKey(e.target.value)}
            />
            <InputGroup.Append>
              <Button onClick={handleSubmit}>Add</Button>
            </InputGroup.Append>
          </InputGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}

function shouldBeChecked(config) {
  if (config) {
    return true;
  } else {
    return false;
  }
}
