import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function TestLabelInput({ label, updateTestLabel }) {
  function onChange(e) {
    if (e.target.value.length < 61) {
      updateTestLabel(e.target.value);
    }
  }
  return (
    <FormGroup controlId="test-label-input">
      <FormLabel>
        <FormattedMessage
          id="TestLabel.label"
          defaultMessage="Short Description"
        />
      </FormLabel>
      <FormControl
        type="text"
        value={label || ""}
        onChange={onChange}
        placeholder="Optional custom label for this test"
      />
    </FormGroup>
  );
}
