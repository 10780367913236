import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import AddTestAttributeButton from "./AddTestAttributeButton.js";
import JobAttribute from "./JobAttribute.js";
import { initJobAttributes } from "./utilities.js";

export default function AttributesPlan({
  attributesPlan,
  setAttributesPlan,
  productInfo,
}) {
  function addAttributesToAttributesPlan() {
    let jobAttributes = initJobAttributes();
    setAttributesPlan([
      ...attributesPlan,
      { jobAttributes: jobAttributes, edit: true },
    ]);
  }

  function updateAttributesPlanItem(index, jobAttributes) {
    attributesPlan[index].jobAttributes = jobAttributes;
    setAttributesPlan([...attributesPlan]);
  }

  function toggleAttributesPlanItemEdit(index) {
    attributesPlan[index].edit = !attributesPlan[index].edit;
    setAttributesPlan([...attributesPlan]);
  }

  function reorderAttributesPlan(startIndex, endIndex) {
    let [removed] = attributesPlan.splice(startIndex, 1);
    attributesPlan.splice(endIndex, 0, removed);
    setAttributesPlan([...attributesPlan]);
  }

  function deleteAttribute(index) {
    attributesPlan.splice(index, 1);
    setAttributesPlan([...attributesPlan]);
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    reorderAttributesPlan(result.source.index, result.destination.index);
  }

  const attributesPlanList = attributesPlan.map((attributesPlanItem, index) => (
    <Draggable key={index} draggableId={"draggable-" + index} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <JobAttribute
            productInfo={productInfo}
            jobAttributes={attributesPlanItem.jobAttributes}
            edit={attributesPlanItem.edit}
            index={index}
            toggleEdit={toggleAttributesPlanItemEdit}
            updateAttributesPlanItem={updateAttributesPlanItem}
            deleteAttribute={deleteAttribute}
          />
          <br />
        </div>
      )}
    </Draggable>
  ));
  return (
    <Card>
      <Card.Body>
        <h4>
          <FormattedMessage
            id="attributesplan.title"
            defaultMessage="Job Attributes"
          />
          <br />
          <small>
            <FormattedMessage
              id="attributesplan.description"
              defaultMessage="Add custom editable job attributes"
            />
          </small>
        </h4>
        <br />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="attributesPlan">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {attributesPlanList}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <AddAttributes
          addAttributesToAttributesPlan={addAttributesToAttributesPlan}
        />
      </Card.Body>
    </Card>
  );
}

function AddAttributes({ addAttributesToAttributesPlan }) {
  return (
    <Row>
      <Col>
        <AddTestAttributeButton
          addAttributesToAttributesPlan={addAttributesToAttributesPlan}
        />
      </Col>
    </Row>
  );
}
