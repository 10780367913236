import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { useIntl } from "react-intl";

export default function ReferenceInfoInput({
  index,
  _key,
  value,
  updateReferenceInfo,
  showDelete,
  deleteReferenceInfoItem,
  testIndex,
}) {
  let intl = useIntl();
  function handleChange(e) {
    if (e.target.value.length < 61) {
      var newValue = e.target.value;
      // Ensure that fiberNumber values are numbers
      if (_key === "fiberNumber") {
        if (newValue) {
          const intValue = parseInt(newValue);
          if (!(intValue > -1)) {
            return;
          }
          newValue = String(intValue);
        }
      }
      const referenceInfo = { key: _key, value: newValue };
      updateReferenceInfo(index, referenceInfo);
    }
  }

  return (
    <FormGroup controlId={_key + testIndex}>
      <FormLabel>
        {intl.messages.hasOwnProperty(_key)
          ? intl.formatMessage({ id: _key })
          : _key}
      </FormLabel>
      {showDelete && (
        <button
          type="button"
          className="close"
          aria-hidden="true"
          aria-label={"Delete RI " + index}
          onClick={() => deleteReferenceInfoItem(index)}
        >
          &times;
        </button>
      )}
      <FormControl type="text" value={value} onChange={handleChange} />
    </FormGroup>
  );
}
