import { useCallback } from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  FormCheck,
  Form,
  Card,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { validateValue, cloneItem, configValUI } from "./utilities";
import HelpModal from "./HelpModal.js";
import { ToggleEditButton } from "./SelectOrDeleteTest.js";

let toggleEditObj = {};

function showThresholdSet(config) {
  return [config["location"], config["odnClass"]].includes("user");
}

function ObjectConfig({
  testPlanIndex,
  testIndex,
  value,
  label,
  nestedValue,
  nestedLabel,
  updateConfig,
  updateNestedConfigToggleEdit,
  configSchema,
}) {
  const nestedLab = nestedLabel ? nestedLabel : label;
  const nestedVal = nestedValue ? nestedValue : value;
  let removeThresholdsButton;
  const validateInput = useCallback(
    (input, field) => {
      const inputProperties = configSchema.properties[field];
      const inputType = inputProperties.type;
      const min = inputProperties.minimum;
      const max = inputProperties.maximum;
      const pattern = inputProperties.pattern;
      const isInputValid = validateValue(input, inputType, min, max, pattern);
      return isInputValid;
    },
    [configSchema]
  );

  if (value == null) {
    return null;
  }

  let items = Object.entries(nestedVal).map(
    ([subLabel, subValue], valueObjIndex) => {
      let formInput;
      function handleChange(val, lab) {
        const floatVal = parseFloat(val);
        // make sure that a string that starts with a number is not partially converted to float
        if (floatVal.toString().length === val.length && !isNaN(floatVal)) {
          nestedVal[lab] = floatVal;
        } else {
          nestedVal[lab] = val;
        }
        updateConfig(label, value);
      }
      if (subLabel === "thresholdSet" && !showThresholdSet(nestedVal)) {
        return null;
      }
      if (Array.isArray(subValue)) {
        return subValue.map((nestedArrObj, nestedArrObjIndex) => {
          let addThresholdButton;
          // Show the add threshold button only after the last item in the array
          if (nestedArrObjIndex === subValue.length - 1) {
            addThresholdButton = (
              <Button
                key={
                  nestedLab +
                  subLabel +
                  testIndex +
                  valueObjIndex +
                  nestedArrObjIndex +
                  "button"
                }
                variant="outline-primary"
                className="mt-4 mb-2"
                id={subLabel + "Button"}
                onClick={() => {
                  nestedVal[subLabel].push(cloneItem(subValue[0]));
                  updateConfig(label, value);
                }}
              >
                <FormattedMessage
                  id={subLabel + "Button"}
                  defaultMessage={subLabel}
                />
              </Button>
            );
          }
          return (
            <div
              className="mt-4"
              key={
                nestedLab +
                subLabel +
                testIndex +
                valueObjIndex +
                nestedArrObjIndex
              }
            >
              <ObjectConfig
                key={
                  nestedLab +
                  subLabel +
                  testIndex +
                  valueObjIndex +
                  nestedArrObjIndex
                }
                testPlanIndex={testPlanIndex}
                testIndex={`${testIndex}-${subLabel}-${nestedArrObjIndex}`}
                value={value}
                label={label}
                nestedValue={nestedArrObj}
                nestedLabel={subLabel}
                updateConfig={updateConfig}
                updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
                configSchema={configSchema.properties[subLabel].items}
              />
              {addThresholdButton}
            </div>
          );
        });
      } else if (typeof subValue === "object") {
        return (
          <ObjectConfig
            key={nestedLab + subLabel + testIndex + valueObjIndex}
            testPlanIndex={testPlanIndex}
            testIndex={testIndex + subLabel}
            value={value}
            label={label}
            nestedValue={subValue}
            nestedLabel={subLabel}
            updateConfig={updateConfig}
            updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
            configSchema={configSchema.properties[subLabel]}
          />
        );
      } else if (configSchema.properties[subLabel].enum) {
        const configEnum = configSchema.properties[subLabel].enum;
        const options = configEnum.map((configVal, index) => (
          <option
            key={nestedLab + subLabel + testIndex + valueObjIndex + index}
            value={configVal}
          >
            {configValUI(configVal)}
          </option>
        ));

        formInput = (
          <FormControl
            key={nestedLab + subLabel + testIndex + valueObjIndex}
            as="select"
            value={subValue}
            onChange={(e) => {
              handleChange(e.target.value, subLabel);
            }}
          >
            {options}
          </FormControl>
        );
      } else if (configSchema.properties[subLabel].type === "number") {
        formInput = (
          <FormControl
            key={nestedLab + subLabel + testIndex + valueObjIndex}
            data-testid={nestedLab + subLabel + testIndex + valueObjIndex}
            type="number"
            step={0.1}
            precision={1}
            value={subValue}
            onChange={(e) => {
              handleChange(e.target.value, subLabel);
            }}
          />
        );
      } else if (configSchema.properties[subLabel].type === "boolean") {
        return (
          <FormGroup
            key={label + nestedLab + subLabel + testIndex + valueObjIndex}
            controlId={label + nestedLab + subLabel + testIndex + valueObjIndex}
          >
            <FormCheck
              key={nestedLab + subLabel + testIndex + valueObjIndex}
              type="checkbox"
              checked={subValue}
              inline
              onChange={(e) => {
                handleChange(e.target.checked, subLabel);
              }}
            />
            <FormLabel>
              <FormattedMessage id={subLabel} />
            </FormLabel>
          </FormGroup>
        );
      } else {
        formInput = (
          <FormControl
            key={nestedLab + subLabel + testIndex + valueObjIndex}
            data-testid={nestedLab + subLabel + testIndex + valueObjIndex}
            type="text"
            value={subValue}
            onChange={(e) => {
              handleChange(e.target.value, subLabel);
            }}
          />
        );
      }
      const inputProperties = configSchema.properties[subLabel];
      const isValid = validateInput(subValue, subLabel);
      return (
        <FormGroup
          key={nestedLab + subLabel + testIndex + valueObjIndex}
          controlId={nestedLab + subLabel + testIndex + valueObjIndex}
        >
          <FormLabel>
            <FormattedMessage id={subLabel} />
          </FormLabel>
          <HelpModal title={subLabel} property={inputProperties} />
          {formInput}
          <Form.Text className={isValid ? "text-success" : "text-danger"}>
            <FormattedMessage
              id={isValid ? "Input is valid" : "Input not valid"}
            />
          </Form.Text>
        </FormGroup>
      );
    }
  );
  // Do not show the remove button for top level card
  if (nestedLab !== label) {
    removeThresholdsButton = (
      <RemoveThresholdsFromTestButton
        updateConfig={updateConfig}
        label={label}
        value={value}
        testIndex={testIndex}
      />
    );
  }

  if (!toggleEditObj[`${testPlanIndex}${testIndex}${nestedLab}`]) {
    items = (
      <Row style={{ textAlign: "left" }}>
        <Col xs={11}>
          <strong>
            <FormattedMessage id={nestedLab} />
          </strong>
        </Col>
      </Row>
    );
  }
  return (
    <Card>
      <Card.Body className="App">
        <Row>
          <Col xs={10}>{items}</Col>
          <Col xs={2}>
            <ToggleEditButton
              toggleEdit={() => {
                toggleEdit(
                  updateNestedConfigToggleEdit,
                  testPlanIndex,
                  `${testPlanIndex}${testIndex}${nestedLab}`,
                  toggleEditObj
                );
              }}
              index={`${testPlanIndex}${testIndex}${nestedLab}`}
            />
            {removeThresholdsButton}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function toggleEdit(
  updateNestedConfigToggleEdit,
  testPlanIndex,
  configIndex,
  nestedObj
) {
  nestedObj[configIndex] = nestedObj[configIndex] ? false : true;
  updateNestedConfigToggleEdit(testPlanIndex, nestedObj);
}

function RemoveThresholdsFromTestButton({
  updateConfig,
  label,
  value,
  testIndex,
}) {
  function removeThresholds() {
    const thresholdSetIdx = testIndex.split("-")[2];
    if (value.thresholds.thresholdSet.length > 1) {
      value.thresholds.thresholdSet.splice(thresholdSetIdx, 1);
    }
    updateConfig(label, value);
  }
  return (
    <button
      type="button"
      className="close"
      aria-hidden="true"
      onClick={removeThresholds}
    >
      &times;
    </button>
  );
}

export default ObjectConfig;
