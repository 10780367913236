import { useState } from "react";
import Papa from "papaparse";
import {
  ButtonGroup,
  Button,
  Card,
  FormGroup,
  FormCheck,
  Form,
  Tabs,
  Tab,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";

import ReferenceInfoDisplay from "./ReferenceInfoDisplay.js";
import TestLabelInput from "./TestLabelInput.js";
import ConfigInputs from "./ConfigInputs.js";
import { initPlannedTest } from "./utilities.js";

export default function AddMultipleTestsButton(props) {
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(false);
  }
  function handleShow() {
    setShow(true);
  }
  return (
    <div>
      <Button variant="outline-primary" onClick={handleShow}>
        Add Multiple Tests
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Multiple Tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddMultipleTestsDialog
            productInfo={props.productInfo}
            addMultipleTestsToTestPlan={props.addMultipleTestsToTestPlan}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function AddMultipleTestsDialog({
  productInfo,
  addMultipleTestsToTestPlan,
  handleClose,
}) {
  const [tests, setTests] = useState({});
  const [referenceInfoList, setReferenceInfoList] = useState([]);
  const { filterFunc, availableTests } = productInfo.addMultiple;

  function parseLabelListCSV(e) {
    function labelsToReferenceInfo(results) {
      const refInfoList = results.data.filter(filterFunc).map((labelArray) => {
        const referenceInfo = Object.entries(labelArray).map(
          ([key, value]) => ({ key: key, value: value })
        );
        return { enabled: true, referenceInfo: referenceInfo };
      });
      setReferenceInfoList(refInfoList);
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }

    Papa.parse(e.target.files[0], {
      complete: labelsToReferenceInfo,
      header: true,
      skipEmptyLines: true,
    });
  }

  function updateConfig(testType, label, value) {
    setTests((prevTests) => {
      prevTests[testType].config[label] = value;
      return { ...prevTests };
    });
  }

  function updateTestLabel(testType, label) {
    setTests((prevTests) => {
      prevTests[testType].testLabel = label;
      return { ...prevTests };
    });
  }

  function toggelTest(e) {
    const testType = e.target.id;
    const checked = e.target.checked;
    setTests((prevTests) => {
      if (checked) {
        prevTests[testType] = initPlannedTest(productInfo, testType);
      } else {
        delete prevTests[testType];
      }
      return { ...prevTests };
    });
  }

  function toggelReferenceInfo(index) {
    setReferenceInfoList((prevRefInfoList) => {
      prevRefInfoList[index].enabled = !prevRefInfoList[index].enabled;
      return [...prevRefInfoList];
    });
  }

  function addByLabel() {
    const plannedTests = [];
    for (let item of referenceInfoList) {
      if (item.enabled) {
        for (let plannedTest of Object.values(tests)) {
          plannedTests.push(createTest(plannedTest, item.referenceInfo));
        }
      }
    }
    addMultipleTestsToTestPlan(plannedTests);
    handleClose();
  }

  function addByTest() {
    const plannedTests = [];
    for (let plannedTest of Object.values(tests)) {
      for (let item of referenceInfoList) {
        if (item.enabled) {
          plannedTests.push(createTest(plannedTest, item.referenceInfo));
        }
      }
    }
    addMultipleTestsToTestPlan(plannedTests);
    handleClose();
  }

  return (
    <Card>
      <Card.Body>
        <CheckBoxes availableTests={availableTests} toggelTest={toggelTest} />
        <AddMultipleTabs
          tests={tests}
          referenceInfoList={referenceInfoList}
          parseLabelListCSV={parseLabelListCSV}
          toggelReferenceInfo={toggelReferenceInfo}
          updateConfig={updateConfig}
          updateTestLabel={updateTestLabel}
          productInfo={productInfo}
        />
      </Card.Body>
      <Card.Footer>
        <AddTestsButtons addByTest={addByTest} addByLabel={addByLabel} />
      </Card.Footer>
    </Card>
  );
}

function CheckBoxes({ availableTests, toggelTest }) {
  const intl = useIntl();
  const checkBoxes = availableTests.map((testType, index) => (
    <FormCheck
      type="switch"
      label={intl.formatMessage({ id: testType })}
      id={testType}
      inline
      onChange={toggelTest}
      key={index}
    />
  ));
  return <FormGroup>{checkBoxes}</FormGroup>;
}

function AddMultipleTabs({
  tests,
  referenceInfoList,
  parseLabelListCSV,
  toggelReferenceInfo,
  updateConfig,
  updateTestLabel,
  productInfo,
}) {
  const intl = useIntl();
  var tabs = [
    makeLabelsTab(
      parseLabelListCSV,
      referenceInfoList,
      toggelReferenceInfo,
      productInfo,
      intl
    ),
  ].concat(
    makeTestTabs(tests, updateTestLabel, updateConfig, productInfo, intl)
  );
  return (
    <Tabs
      style={{ margin: 0 }} //needed due to github.com/react-bootstrap/react-bootstrap/issues/4771
      defaultActiveKey={"Labels"}
      id={"Multiple Test Selections"}
    >
      {tabs}
    </Tabs>
  );
}

function makeLabelsTab(
  parseLabelListCSV,
  referenceInfoList,
  toggelReferenceInfo,
  productInfo,
  intl
) {
  const referenceInfoListDisplay = referenceInfoList.map((item, index) => (
    <FormCheck
      type="switch"
      index={index}
      id={"label-check-" + index}
      key={index}
      style={{ marginBottom: 10 }}
    >
      <Form.Check.Input
        type={"checkbox"}
        checked={item.enabled}
        onChange={toggelReferenceInfo.bind(this, index)}
      />
      <Form.Check.Label>
        <ReferenceInfoDisplay referenceInfo={item.referenceInfo} />
      </Form.Check.Label>
    </FormCheck>
  ));

  return (
    <Tab eventKey={"Labels"} key={0} title="Labels">
      <Card>
        <Card.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.File
                  id="labelList"
                  label={intl.formatMessage({
                    id: "addMultiple.labelList",
                    defaultMessage: "Label List (.csv)",
                  })}
                  data-browse={intl.formatMessage({
                    id: "browse",
                    defaultMessage: "Browse",
                  })}
                  onChange={parseLabelListCSV}
                  accept={".csv"}
                  custom
                />
              </Col>
              <Col xs={{ span: 6 }} style={{ textAlign: "right" }}>
                <a href={productInfo.addMultiple.csvTemplate}>
                  <FormattedMessage
                    id={"addMultiple.labelListTempalte"}
                    defaultMessage={"Label List Template"}
                  />
                </a>
              </Col>
            </Row>
          </Form>
          <div style={{ marginTop: 10 }}>{referenceInfoListDisplay}</div>
        </Card.Body>
      </Card>
    </Tab>
  );
}

function makeTestTabs(tests, updateTestLabel, updateConfig, productInfo, intl) {
  const tabs = Object.entries(tests).map(([testType, value], index) => (
    <Tab
      eventKey={testType}
      key={index + 1}
      title={intl.formatMessage({ id: testType })}
    >
      <Card>
        <Card.Body>
          {testType === "manualStep" && (
            <TestLabelInput
              label={value.testLabel}
              updateTestLabel={updateTestLabel.bind(this, testType)}
            />
          )}
          <ConfigInputs
            config={value.config}
            updateConfig={updateConfig.bind(this, testType)}
            configSchema={productInfo.swagger.definitions[testType]}
            testIndex={index}
          />
        </Card.Body>
      </Card>
    </Tab>
  ));
  return tabs;
}

function AddTestsButtons({ addByTest, addByLabel }) {
  return (
    <ButtonGroup>
      <Button variant="outline-primary" onClick={addByLabel}>
        <FormattedMessage
          id={"addMultiple.addByLabel"}
          defaultMessage={"Add by Label"}
        />
      </Button>
      <Button variant="outline-primary" onClick={addByTest}>
        <FormattedMessage
          id={"addMultiple.addByTest"}
          defaultMessage={"Add by Test"}
        />
      </Button>
    </ButtonGroup>
  );
}

function createTest(plannedTest, referenceInfo) {
  let uniquePlannedTest = JSON.parse(JSON.stringify(plannedTest));
  let uniqueReferenceInfo = JSON.parse(JSON.stringify(referenceInfo));
  uniquePlannedTest.referenceInfo = uniqueReferenceInfo;
  return { plannedTest: uniquePlannedTest, edit: false };
}
