import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Papa from "papaparse";

function AddLocationsButton({ updateTestLocations, testLocations }) {
  const [show, setShow] = useState(false);

  const intl = useIntl();
  function loadTestLocationsFile(e) {
    function loadHandler(e) {
      testLocations = csvToArray(e.target.result);
      const locationsListContent = document.getElementById("locations-list");
      locationsListContent.style.display = "block";
      locationsListContent.value = testLocations;
      updateTestLocations(testLocations);
    }

    var reader = new FileReader();
    reader.onload = loadHandler;
    reader.readAsText(e.target.files[0]);
    setShow(false);
  }
  const handleTextAreaUpdate = (e) => {
    testLocations = e.target.value ? e.target.value.split(",") : [];
    updateTestLocations(testLocations);
  };

  return (
    <>
      <Button
        id="upload-locations-button"
        variant="btn-outline-primary"
        className="btn-outline-primary"
        onClick={() => setShow(true)}
      >
        <FormattedMessage
          id="loadtestlocations.message"
          defaultMessage="Upload Test Locations"
        />
      </Button>
      <div
        id="locations-list-container"
        className="align-center p-1 txt-area-border"
      >
        <textarea
          placeholder="Upload a CSV file containing the comma separated locations list or enter text. eg. Test Location A,Test Location B"
          className="align-center p-1"
          id="locations-list"
          aria-label="locations-textarea"
          onChange={handleTextAreaUpdate}
          value={testLocations}
        ></textarea>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="loadtestlocations.message"
              defaultMessage="Upload Test Locations"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.File
            label={intl.formatMessage({
              id: "loadtestlocations.select",
              defaultMessage: "Select Job File",
            })}
            id="loadTestLocationsInput"
            onChange={loadTestLocationsFile}
            accept={".csv"}
            data-browse={intl.formatMessage({
              id: "browse",
              defaultMessage: "Browse",
            })}
            custom
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

function csvToArray(csvString) {
  // eg. Label List,\r\nC01 F001,\r\n\r\nC01 F002,\r\nC01 F003,\r\nC01 F004,\r\nC01 F005,
  // First element is the header. All the others are locations.
  const parsedCsvArray = Papa.parse(csvString, {
    header: false,
    skipEmptyLines: true,
  });
  let locations = [];
  for (let location of parsedCsvArray.data) {
    locations.push(location[0]);
  }
  // skip the first value in the array as it is the header.
  return locations.slice(1);
}
export default AddLocationsButton;
