import { Fragment } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { Container, Col, Row, Navbar, Image } from "react-bootstrap";

import Job from "./Job.js";
import LoginBlock from "./Login.js";
import { useFlags } from "./FeatureFlags";

import logo from "../Viavi_Color_png.jpg";

export default function AppLayout({
  ssServer,
  accessToken,
  setServer,
  logout,
}) {
  return (
    <div className="App">
      <Container>
        <Header
          ssServer={ssServer}
          accessToken={accessToken}
          setServer={setServer}
          logout={logout}
        />
        <Row className="show-grid">
          <Col>
            <Job accessToken={accessToken} ssServer={ssServer} />
          </Col>
        </Row>
      </Container>
      <hr />
      <footer>
        <p>
          <FormattedMessage
            id="applayout.footerMessage"
            defaultMessage="Thanks for using Job Manager"
          />
        </p>
      </footer>
    </div>
  );
}

export function Header({ ssServer, accessToken, setServer, logout }) {
  const intl = useIntl();
  const navbar = useFlags().navbar;
  if (navbar) {
    return (
      <Fragment>
        <Navbar bg="light" expand="xl">
          <Navbar.Brand>
            <Image src={logo} style={{ height: 20 }} />
          </Navbar.Brand>
          <Navbar.Brand>
            {intl.formatMessage({ id: "applayout.title" })}
          </Navbar.Brand>
          <Navbar.Text>
            <FormattedMessage
              id="applayout.description"
              defaultMessage="Create jobs for Viavi Instruments"
            />
          </Navbar.Text>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <LoginBlock
              ssServer={ssServer}
              accessToken={accessToken}
              setServer={setServer}
              logout={logout}
            />
          </Navbar.Collapse>
        </Navbar>
        <br />
      </Fragment>
    );
  } else {
    return (
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <div className="pb-2 mt-4 mb-2">
            <Image
              style={{ maxHeight: 80 }}
              src={logo}
              alt="Viavi Solutions Logo"
              fluid
            />
            <span className="header-text">
              <div className="h1">
                <FormattedMessage
                  id="applayout.title"
                  defaultMessage="Welcome to Job Manager"
                />
              </div>
            </span>
            <p className="lead">
              <strong>
                <FormattedMessage
                  id="applayout.description"
                  defaultMessage="Create jobs for Viavi Instruments"
                />
              </strong>
            </p>
          </div>
        </Col>
        <Col lg={3}>
          <LoginBlock
            ssServer={ssServer}
            accessToken={accessToken}
            setServer={setServer}
            logout={logout}
          />
        </Col>
      </Row>
    );
  }
}
