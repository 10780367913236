const testTypesNSC100 = {
  "OneCheck Ethernet": {
    testType: "OneCheck Ethernet",
    launchableType: "oneCheckEthernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck PON": {
    testType: "OneCheck PON",
    launchableType: "oneCheckPON",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck WiFi": {
    testType: "OneCheck WiFi",
    launchableType: "oneCheckWiFi",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "WiFi Expert": {
    testType: "WiFi Expert",
    launchableType: "oneCheckWiFiExpert",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Manual Step": {
    testType: "Manual Step",
    launchableType: "manualStep",
    referenceInfo: [],
  },
};
export default testTypesNSC100;
