import React from "react";

import { JobAttributeInput } from "./JobAttributeInput.js";
import JobAttributeReview from "./JobAttributeReview.js";

export default function JobAttribute({
  productInfo,
  jobAttributes,
  edit,
  index,
  updateAttributesPlanItem,
  toggleEdit,
  deleteAttribute,
}) {
  return edit ? (
    <JobAttributeInput
      productInfo={productInfo}
      jobAttributes={jobAttributes}
      index={index}
      updateAttributesPlanItem={updateAttributesPlanItem}
      toggleEdit={toggleEdit}
      deleteAttribute={deleteAttribute}
    />
  ) : (
    <JobAttributeReview
      jobAttributes={jobAttributes}
      index={index}
      toggleEdit={toggleEdit}
      deleteAttribute={deleteAttribute}
    />
  );
}
