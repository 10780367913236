import { Button, ButtonGroup, Card } from "react-bootstrap";

import { displayLabels } from "../products/productInfo.js";

export default function ProductSelector({ product, setProduct }) {
  const products = Object.assign({}, displayLabels);

  const productButtons = Object.keys(products).map((productKey, index) => {
    const active = product === productKey;
    return (
      <Button
        variant="outline-primary"
        id={productKey}
        onClick={(e) => setProduct(e.target.id)}
        key={index}
        active={active}
        style={{ width: "200px" }}
      >
        {products[productKey]}
      </Button>
    );
  });
  return (
    <Card>
      <Card.Body>
        <h4>Select Product</h4>
        <ButtonGroup className="align-center">{productButtons}</ButtonGroup>
      </Card.Body>
    </Card>
  );
}
