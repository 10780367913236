import { Component } from "react";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";

import "./App.css";

import AppLayout from "./AppLayout.js";
import FlagProvider from "./FeatureFlags";

const queryString = require("query-string");

const accessTokenKey = "access_token";
const SS_SERVER_KEY = "ssServer";

class App extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.setServer = this.setServer.bind(this);
    this.state = {
      accessToken: "",
      shouldRender: false,
      ssServer: "prod_us",
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const parsedHash = queryString.parse(window.location.hash);
    const hashAccessToken = parsedHash.access_token;
    const cookies = new Cookies();
    if (hashAccessToken) {
      cookies.set(accessTokenKey, hashAccessToken);
      window.location = window.location.pathname;
      return null;
    }
    const accessToken = cookies.get(accessTokenKey);
    const ssServer = cookies.get(SS_SERVER_KEY) || this.state.ssServer;
    cookies.set(SS_SERVER_KEY, ssServer);
    this.setState({
      accessToken: accessToken,
      shouldRender: true,
      ssServer: ssServer,
    });
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove(accessTokenKey);
    this.setState({ accessToken: "" });
  }

  setServer(ssServer) {
    const cookies = new Cookies();
    cookies.set(SS_SERVER_KEY, ssServer);
    this.setState({ ssServer: ssServer });
  }

  render() {
    if (!this.state.shouldRender) {
      return null;
    }
    const { accessToken, ssServer } = this.state;
    return (
      <FlagProvider>
        <AppLayout
          ssServer={ssServer}
          accessToken={accessToken}
          setServer={this.setServer}
          logout={this.logout}
        />
      </FlagProvider>
    );
  }
}

export default App;
