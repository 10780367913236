import React from "react";
import { useIntl } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";

import { DeleteTestButton, ToggleEditButton } from "./SelectOrDeleteTest.js";

export default function PlannedTestReview({
  index,
  jobAttributes,
  toggleEdit,
  deleteAttribute,
}) {
  const { label, uniqueId, defaultValue, validationType, validValueList } =
    jobAttributes;
  const intl = useIntl();
  const attributeName = label;
  return (
    <Card body style={{ textAlign: "left" }}>
      <Row>
        <Col xs={11}>
          <strong>{attributeName}</strong>{" "}
          <span key={"uniqueId"}>
            {intl.formatMessage({ id: "Unique ID" })}:{" "}
            <strong>{uniqueId} </strong>
          </span>
          <span key={"defaultValue"}>
            {intl.formatMessage({ id: "Default Value" })}:{" "}
            <strong>{defaultValue} </strong>
          </span>
          <span key={"validationType"}>
            {intl.formatMessage({ id: "Validation" })}:{" "}
            <strong>{validationType} </strong>
          </span>
          {validationType !== "None" && (
            <span key={"validValueList"}>
              {intl.formatMessage({
                id:
                  validationType === "Regular expression"
                    ? "RegEx"
                    : "Valid Value List",
              })}
              : <strong>{validValueList} </strong>
            </span>
          )}
        </Col>
        <Col xs={1}>
          <ToggleEditButton toggleEdit={toggleEdit} index={index} />
          <DeleteTestButton index={index} deleteTest={deleteAttribute} />
        </Col>
      </Row>
    </Card>
  );
}
