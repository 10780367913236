import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { IntlProvider } from "react-intl";
import "./viaviBootstrap.scss";

import App from "./components/App";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
  en: messages_en,
  fr: messages_fr,
};

const language = navigator.language.split(/[-_]/)[0];

ReactGA.initialize("UA-116112034-1");

ReactDOM.render(
  <IntlProvider
    locale={language}
    messages={messages[language] || messages["en"]}
  >
    <App />
  </IntlProvider>,
  document.getElementById("root")
);
