import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function AddTestSetupButton({ addSetupToTest, setup, testPlanIndex }) {
  return (
    <Button
      variant="outline-primary"
      id="add-setup"
      onClick={() => addSetupToTest(setup, testPlanIndex)}
    >
      <FormattedMessage
        id="addSetupButton.message"
        defaultMessage="Add Setup"
      />
    </Button>
  );
}

export default AddTestSetupButton;
