const testTypesOna800 = {
  "Fiber Inspection": {
    testType: "Fiber Inspection",
    referenceInfo: [
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Connector",
        value: "",
      },
    ],
  },
  OTDR: {
    testType: "OTDR",
    referenceInfo: [],
  },
  opticalPower: {
    testType: "opticalPower",
    referenceInfo: [],
  },
  AntennaAlign: {
    testType: "AntennaAlign",
    referenceInfo: [],
  },
  MicrowaveAlign: {
    testType: "MicrowaveAlign",
    referenceInfo: [],
  },
  "CAA Check": {
    testType: "CAA Check",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
    ],
  },
  "CAA Reflection VSWR": {
    testType: "CAA Reflection VSWR",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA Reflection Return Loss": {
    testType: "CAA Reflection Return Loss",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA DTF VSWR": {
    testType: "CAA DTF VSWR",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA DTF Return Loss": {
    testType: "CAA DTF Return Loss",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA 1 Port Cable Loss": {
    testType: "CAA 1 Port Cable Loss",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA 1 Port Phase": {
    testType: "CAA 1 Port Phase",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA 2 Port Transmission": {
    testType: "CAA 2 Port Transmission",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "CAA Smith Chart": {
    testType: "CAA Smith Chart",
    referenceInfo: [
      {
        key: "Sector",
        value: "",
      },
      {
        key: "Band",
        value: "",
      },
      {
        key: "Cable",
        value: "",
      },
      {
        key: "Termination",
        value: "",
      },
    ],
  },
  "RF Sweep Tuned Spectrum": {
    testType: "RF Sweep Tuned Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Channel Power": {
    testType: "RF Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Occupied Bandwidth": {
    testType: "RF Occupied Bandwidth",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Spectrum Emission Mask": {
    testType: "RF Spectrum Emission Mask",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Adjacent Channel Power": {
    testType: "RF Adjacent Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Multi Adjacent Channel Power": {
    testType: "RF Multi Adjacent Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Spurious Emissions": {
    testType: "RF Spurious Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Total Harmonic Distortion": {
    testType: "RF Total Harmonic Distortion",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Field Strength": {
    testType: "RF Field Strength",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF AM/FM Demodulation": {
    testType: "RF AM/FM Demodulation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Gated Sweep": {
    testType: "RF Gated Sweep",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Route Map": {
    testType: "RF Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Internal Power Meter": {
    testType: "RF Internal Power Meter",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RF Power Meter": {
    testType: "RF Power Meter",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "EMF Spectrum": {
    testType: "EMF Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "EMF 5G NR Beam Analysis": {
    testType: "EMF 5G NR Beam Analysis",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "EMF Scanner": {
    testType: "EMF Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA Spectrum": {
    testType: "IA Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA Spectrogram": {
    testType: "IA Spectrogram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA RSSI": {
    testType: "IA RSSI",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA Interference Finder": {
    testType: "IA Interference Finder",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA Radar Chart": {
    testType: "IA Radar Chart",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA PIM Detection Single Carrier": {
    testType: "IA PIM Detection Single Carrier",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "IA PIM Detection Multi Carrier": {
    testType: "IA PIM Detection Multi Carrier",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RT Persistent Spectrum": {
    testType: "RT Persistent Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RT Persistent Spectrogram": {
    testType: "RT Persistent Spectrogram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RT Persistent RSSI": {
    testType: "RT Persistent RSSI",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RT Persistent Interference Finder": {
    testType: "RT Persistent Interference Finder",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RT Persistent Radar Chart": {
    testType: "RT Persistent Radar Chart",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Spectrum": {
    testType: "LTE FDD Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Channel Power": {
    testType: "LTE FDD Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Occupied Bandwidth": {
    testType: "LTE FDD Occupied Bandwidth",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Spectrum Emission Mask": {
    testType: "LTE FDD Spectrum Emission Mask",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD ACLR": {
    testType: "LTE FDD ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Multi-ACLR": {
    testType: "LTE FDD Multi-ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Spurious Emissions": {
    testType: "LTE FDD Spurious Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Power vs Time for Frame": {
    testType: "LTE FDD Power vs Time for Frame",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Constellation": {
    testType: "LTE FDD Constellation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Data Channel": {
    testType: "LTE FDD Data Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Control Channel": {
    testType: "LTE FDD Control Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Subframe": {
    testType: "LTE FDD Subframe",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Frame": {
    testType: "LTE FDD Frame",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Time Alignment Error": {
    testType: "LTE FDD Time Alignment Error",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Data Allocation Map": {
    testType: "LTE FDD Data Allocation Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Carrier Aggregation": {
    testType: "LTE FDD Carrier Aggregation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD OTA Channel Scanner": {
    testType: "LTE FDD OTA Channel Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD OTA ID Scanner": {
    testType: "LTE FDD OTA ID Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD OTA Multipath Profile": {
    testType: "LTE FDD OTA Multipath Profile",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD OTA Control Channel": {
    testType: "LTE FDD OTA Control Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD OTA Datagram": {
    testType: "LTE FDD OTA Datagram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD OTA Route Map": {
    testType: "LTE FDD OTA Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Freq / Time / Power Variation": {
    testType: "LTE FDD Freq / Time / Power Variation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE FDD Power Statistics CCDF": {
    testType: "LTE FDD Power Statistics CCDF",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Spectrum": {
    testType: "LTE TDD Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Channel Power": {
    testType: "LTE TDD Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Occupied Bandwidth": {
    testType: "LTE TDD Occupied Bandwidth",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Spectrum Emission Mask": {
    testType: "LTE TDD Spectrum Emission Mask",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD ACLR": {
    testType: "LTE TDD ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Multi-ACLR": {
    testType: "LTE TDD Multi-ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Spurious Emissions": {
    testType: "LTE TDD Spurious Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Power vs Time for Frame": {
    testType: "LTE TDD Power vs Time for Frame",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Power vs Time for Slot": {
    testType: "LTE TDD Power vs Time for Slot",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Constellation": {
    testType: "LTE TDD Constellation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Data Channel": {
    testType: "LTE TDD Data Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Control Channel": {
    testType: "LTE TDD Control Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Subframe": {
    testType: "LTE TDD Subframe",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Time Alignment Error": {
    testType: "LTE TDD Time Alignment Error",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Data Allocation Map": {
    testType: "LTE TDD Data Allocation Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Carrier Aggregation": {
    testType: "LTE TDD Carrier Aggregation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD OTA Channel Scanner": {
    testType: "LTE TDD OTA Channel Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD OTA ID Scanner": {
    testType: "LTE TDD OTA ID Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD OTA Multipath Profile": {
    testType: "LTE TDD OTA Multipath Profile",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD OTA Control Channel": {
    testType: "LTE TDD OTA Control Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD OTA Datagram": {
    testType: "LTE TDD OTA Datagram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD OTA Route Map": {
    testType: "LTE TDD OTA Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Freq / Time / Power Variation": {
    testType: "LTE TDD Freq / Time / Power Variation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "LTE TDD Power Statistics CCDF": {
    testType: "LTE TDD Power Statistics CCDF",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Trigger Spectrum": {
    testType: "5G NR Trigger Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Channel Power": {
    testType: "5G NR Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Occupied Bandwidth": {
    testType: "5G NR Occupied Bandwidth",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Spectrum Emission Mask": {
    testType: "5G NR Spectrum Emission Mask",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR ACLR": {
    testType: "5G NR ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Multi-ACLR": {
    testType: "5G NR Multi-ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Spurious Emissions": {
    testType: "5G NR Spurious Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Beam Analyzer": {
    testType: "5G NR Beam Analyzer",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Carrier Scanner": {
    testType: "5G NR Carrier Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Route Map": {
    testType: "5G NR Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Beam Availability Index": {
    testType: "5G NR Beam Availability Index",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Freq / Time / Power Variation": {
    testType: "5G NR Freq / Time / Power Variation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Multipath Profile": {
    testType: "5G NR Multipath Profile",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Constellation": {
    testType: "5G NR Constellation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Allocation Mapper": {
    testType: "5G NR Allocation Mapper",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Power vs Time for Frame": {
    testType: "5G NR Power vs Time for Frame",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR Power vs Time for Slot": {
    testType: "5G NR Power vs Time for Slot",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR CPS Analysis": {
    testType: "5G NR CPS Analysis",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G NR CPS Route Map": {
    testType: "5G NR CPS Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM BS Output Power": {
    testType: "5G TM BS Output Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM Occupied Bandwidth": {
    testType: "5G TM Occupied Bandwidth",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM ACLR": {
    testType: "5G TM ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM Multi-ACLR": {
    testType: "5G TM Multi-ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM Operating Band Unwanted Emissions": {
    testType: "5G TM Operating Band Unwanted Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM Transmitter Spurious Emissions": {
    testType: "5G TM Transmitter Spurious Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM Transmit ON/OFF Power": {
    testType: "5G TM Transmit ON/OFF Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM Modulation Quality": {
    testType: "5G TM Modulation Quality",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM MIMO Time Alignment Error": {
    testType: "5G TM MIMO Time Alignment Error",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "5G TM CA Time Alignment Error": {
    testType: "5G TM CA Time Alignment Error",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "NSA Analyzer": {
    testType: "NSA Analyzer",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "NSA Scanner": {
    testType: "NSA Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "NSA Route Map": {
    testType: "NSA Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Spectrum": {
    testType: "DSS Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Channel Power": {
    testType: "DSS Channel Power",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Occupied Bandwidth": {
    testType: "DSS Occupied Bandwidth",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Spectrum Emission Mask": {
    testType: "DSS Spectrum Emission Mask",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS ACLR": {
    testType: "DSS ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Multi-ACLR": {
    testType: "DSS Multi-ACLR",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Spurious Emissions": {
    testType: "DSS Spurious Emissions",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Power vs Time for Frame": {
    testType: "DSS Power vs Time for Frame",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Power vs Time for Slot": {
    testType: "DSS Power vs Time for Slot",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Constellation": {
    testType: "DSS Constellation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Channel Mapper": {
    testType: "DSS Channel Mapper",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Control Channel": {
    testType: "DSS Control Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Subframe": {
    testType: "DSS Subframe",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Frame": {
    testType: "DSS Frame",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Time Alignment Error": {
    testType: "DSS Time Alignment Error",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS Freq / Time / Power Variation": {
    testType: "DSS Freq / Time / Power Variation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS OTA Channel Scanner": {
    testType: "DSS OTA Channel Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS OTA ID Scanner": {
    testType: "DSS OTA ID Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS OTA Multipath Profile": {
    testType: "DSS OTA Multipath Profile",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS OTA Control Channel": {
    testType: "DSS OTA Control Channel",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "DSS OTA Route Map": {
    testType: "DSS OTA Route Map",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "CS Channel Scanner": {
    testType: "CS Channel Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "CS Frequency Scanner": {
    testType: "CS Frequency Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "CS Custom Scanner": {
    testType: "CS Custom Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS Spectrum": {
    testType: "TAGS Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS Spectrogram": {
    testType: "TAGS Spectrogram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS Persistent Spectrum": {
    testType: "TAGS Persistent Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS Persistent Spectrogram": {
    testType: "TAGS Persistent Spectrogram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS RSSI": {
    testType: "TAGS RSSI",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS Interference Finder": {
    testType: "TAGS Interference Finder",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "TAGS Radar Chart": {
    testType: "TAGS Radar Chart",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RFoCPRI Spectrum": {
    testType: "RFoCPRI Spectrum",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "RFoCPRI Spectrogram": {
    testType: "RFoCPRI Spectrogram",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "Blind Scanner": {
    testType: "Blind Scanner",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "Blind Scanner FR2": {
    testType: "Blind Scanner FR2",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  "O-DU Emulation": {
    testType: "O-DU Emulation",
    referenceInfo: [
      {
        key: "Info",
        value: "",
      },
    ],
  },
  Signature: {
    testType: "Signature",
    referenceInfo: [],
  },
  Picture: {
    testType: "Picture",
    referenceInfo: [],
  },
  Ethernet: {
    testType: "Ethernet",
    referenceInfo: [],
  },
  "Optics Self-Test": {
    testType: "Optics Self-Test",
    referenceInfo: [],
  },
  QuickCheck: {
    testType: "QuickCheck",
    referenceInfo: [],
  },
  "Y.1564 SAMComplete": {
    testType: "Y.1564 SAMComplete",
    referenceInfo: [],
  },
  "RFC 2544": {
    testType: "RFC 2544",
    referenceInfo: [],
  },
  "5G NR Discovery": {
    testType: "5G NR Discovery",
    referenceInfo: [],
  },
  "PTP/1588": {
    testType: "PTP/1588",
    referenceInfo: [],
  },
};

export default testTypesOna800;
