import { FormattedMessage } from "react-intl";
import { Card, Row, Col } from "react-bootstrap";

import ReferenceInfoDisplay from "./ReferenceInfoDisplay.js";
import { DeleteTestButton, ToggleEditButton } from "./SelectOrDeleteTest.js";

export default function PlannedTestReview({
  index,
  plannedTest,
  toggleEdit,
  deleteTest,
}) {
  const { testType, testLabel, referenceInfo } = plannedTest;
  const testName = testLabel ? testLabel : <FormattedMessage id={testType} />;
  return (
    <Card body style={{ textAlign: "left" }}>
      <Row>
        <Col xs={11}>
          <strong>{testName}</strong>{" "}
          <ReferenceInfoDisplay referenceInfo={referenceInfo} />
        </Col>
        <Col xs={1}>
          <ToggleEditButton toggleEdit={toggleEdit} index={index} />
          <DeleteTestButton index={index} deleteTest={deleteTest} />
        </Col>
      </Row>
    </Card>
  );
}
