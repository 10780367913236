import { PlannedTestInput } from "./PlannedTestInput.js";
import PlannedTestReview from "./PlannedTestReview.js";

export default function PlannedTest({
  productInfo,
  plannedTest,
  edit,
  index,
  updateTestPlanItem,
  updateNestedConfigToggleEdit,
  toggleEdit,
  deleteTest,
  addSetupToTest,
  removeSetupFromTest,
}) {
  return edit ? (
    <PlannedTestInput
      productInfo={productInfo}
      plannedTest={plannedTest}
      index={index}
      updateTestPlanItem={updateTestPlanItem}
      updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
      toggleEdit={toggleEdit}
      deleteTest={deleteTest}
      addSetupToTest={addSetupToTest}
      removeSetupFromTest={removeSetupFromTest}
    />
  ) : (
    <PlannedTestReview
      plannedTest={plannedTest}
      index={index}
      toggleEdit={toggleEdit}
      deleteTest={deleteTest}
    />
  );
}
