import { FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export function SelectTest({ index, selectValue, options, changeTestType }) {
  const intl = useIntl();
  const optionList = makeOptionList(intl, options);
  return (
    <FormGroup controlId={"select-test-" + index}>
      <FormLabel>
        <FormattedMessage
          id="selectOrDeleteTest.message"
          defaultMessage="Select a test type"
        />
      </FormLabel>
      <FormControl
        data-testid={`select-test-${selectValue}`}
        as="select"
        value={selectValue}
        onChange={changeTestType}
        autoFocus={true}
      >
        {optionList}
      </FormControl>
    </FormGroup>
  );
}

export function DeleteTestButton(props) {
  function deleteTest() {
    props.deleteTest(props.index);
  }
  return (
    <button
      type="button"
      className="close"
      aria-hidden="true"
      onClick={deleteTest}
    >
      &times;
    </button>
  );
}

function makeOptionList(intl, options) {
  const optionList = options.map((optionValue, index) => (
    <option key={index} value={optionValue}>
      {intl.formatMessage({ id: optionValue })}
    </option>
  ));
  return optionList;
}

export function ToggleEditButton({ toggleEdit, index }) {
  return (
    <Button
      data-testid={`toggle-edit${index}`}
      size="sm"
      variant="link"
      style={{
        border: "none",
        padding: 0,
      }}
      aria-label={"Toggle Edit " + index}
      onClick={() => toggleEdit(index)}
    >
      <FontAwesomeIcon icon={faEllipsisV} />
    </Button>
  );
}
