const testTypesOneExpertCATV = {
  "SmartID Coax Check": {
    testType: "SmartID Coax Check",
    launchableType: "coaxCheck",
    referenceInfo: [],
  },
  Ethernet: {
    testType: "Ethernet",
    launchableType: "ethernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Fiber Inspection": {
    testType: "Fiber Inspection",
    launchableType: "fiberInspection",
    referenceInfo: [],
  },
  "SmartID OneCheck Coax": {
    testType: "SmartID OneCheck Coax",
    launchableType: "oneCheckCoax",
    referenceInfo: [],
  },
  "OneCheck Ethernet": {
    testType: "OneCheck Ethernet",
    launchableType: "oneCheckEthernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck Fiber": {
    testType: "OneCheck Fiber",
    launchableType: "oneCheckFiber",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Optical Power Meter": {
    testType: "Optical Power Meter",
    launchableType: "powerMeter",
    referenceInfo: [],
  },
  "WiFi Scan": {
    testType: "WiFi Scan",
    launchableType: "wifiScan",
    referenceInfo: [],
  },
  "OneCheck WiFi": {
    testType: "OneCheck WiFi",
    launchableType: "oneCheckWiFi",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "WiFi Site Assessment": {
    testType: "WiFi Site Assessment",
    launchableType: "wfaSiteAssessment",
    referenceInfo: [],
  },
  "WiFi Smart Channel Wizard": {
    testType: "WiFi Smart Channel Wizard",
    launchableType: "wfaSmartChannelWizard",
    referenceInfo: [],
  },
  OneCheck: {
    testType: "OneCheck",
    launchableType: "onecheck",
    referenceInfo: [],
  },
  "Ingress Scan": {
    testType: "Ingress Scan",
    launchableType: "ingressscanmode",
    referenceInfo: [],
  },
  "Channel Check": {
    testType: "Channel Check",
    launchableType: "channelcheck",
    referenceInfo: [],
  },
  "DOCSIS Check": {
    testType: "DOCSIS Check",
    launchableType: "docsischeck",
    referenceInfo: [],
  },
  Spectrum: {
    testType: "Spectrum",
    launchableType: "spectrummode",
    referenceInfo: [],
  },
  "Quick Check": {
    testType: "Quick Check",
    launchableType: "quickcheck",
    referenceInfo: [],
  },
  "TDR - Drop Check": {
    testType: "TDR - Drop Check",
    launchableType: "tdrmode.dropcheck",
    referenceInfo: [],
  },
  "TDR - Cable Length": {
    testType: "TDR - Cable Length",
    launchableType: "tdrmode.cablelength",
    referenceInfo: [],
  },
  "TDR - Home TDR": {
    testType: "TDR - Home TDR",
    launchableType: "tdrmode.hometdr",
    referenceInfo: [],
  },
  "OneCheck Expert": {
    testType: "OneCheck Expert",
    launchableType: "onecheckexpert",
    referenceInfo: [],
  },
  "Ingress Expert": {
    testType: "Ingress Expert",
    launchableType: "ingressexpertmode",
    referenceInfo: [],
  },
  "Channel Expert": {
    testType: "Channel Expert",
    launchableType: "channelexpert",
    referenceInfo: [],
  },
  "DOCSIS Expert": {
    testType: "DOCSIS Expert",
    launchableType: "docsisexpert",
    referenceInfo: [],
  },
  "Quick Check Expert - Quick Check Mode": {
    testType: "Quick Check Expert - Quick Check Mode",
    launchableType: "quickcheckexpert.quickcheck",
    referenceInfo: [],
  },
  "Quick Check Expert - Full Scan Mode": {
    testType: "Quick Check Expert - Full Scan Mode",
    launchableType: "quickcheckexpert.fullscan",
    referenceInfo: [],
  },
  "Forward Sweep": {
    testType: "Forward Sweep",
    launchableType: "Sweep - Forward",
    referenceInfo: [],
  },
  "Forward Sweep Unreferenced": {
    testType: "Forward Sweep Unreferenced",
    launchableType: "Sweep - Forward Unreferenced",
    referenceInfo: [],
  },
  "Reverse Sweep": {
    testType: "Reverse Sweep",
    launchableType: "Sweep - Reverse",
    referenceInfo: [],
  },
  "Reverse Sweep Unreferenced": {
    testType: "Reverse Sweep Unreferenced",
    launchableType: "Sweep - Reverse Unreferenced",
    referenceInfo: [],
  },
  "Sweepless Sweep": {
    testType: "Sweepless Sweep",
    launchableType: "Sweep - Sweepless",
    referenceInfo: [],
  },
  "Sweepless Sweep Unreferenced": {
    testType: "Sweepless Sweep Unreferenced",
    launchableType: "Sweep - Sweepless Unreferenced",
    referenceInfo: [],
  },
  "Reverse Sweepless": {
    testType: "Reverse Sweepless",
    launchableType: "Sweep - Reverse Sweepless",
    referenceInfo: [],
  },
  "Reverse Sweepless Unreferenced": {
    testType: "Reverse Sweepless Unreferenced",
    launchableType: "Sweep - Reverse Sweepless Unreferenced",
    referenceInfo: [],
  },

  "Manual Step": {
    testType: "Manual Step",
    launchableType: "manualStep",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
};

export default testTypesOneExpertCATV;
