import { Row, Col, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function AddLocationButton({ addLocationToplannedTest }) {
  return (
    <Row>
      <Col className="align-left">
        <Button
          variant="outline-primary"
          id="add-location"
          onClick={addLocationToplannedTest}
        >
          <FormattedMessage
            id="addtestlocation.message"
            defaultMessage="Add Test Location"
          />
        </Button>
      </Col>
    </Row>
  );
}
