import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import AddLocationsButton from "./AddLocationsButton";
import AddMultipleTestsButton from "./AddMultipleTestsButton.js";
import AddTestButton from "./AddTestButton.js";
import PlannedTest from "./PlannedTest.js";
import {
  getMaxSetupCount,
  getMinSetupCount,
  makeFirstTest,
} from "./utilities.js";

export default function TestPlan({
  testPlan,
  setTestPlan,
  productInfo,
  updateTestLocations,
  isTestLocationsListEmpty,
  testLocations,
}) {
  function addTestToTestPlan() {
    const plannedTest = isTestPlanEmpty()
      ? makeFirstTest(productInfo)
      : cloneLastItem(testPlan);
    // In RFVision test, Some value of the inputs is required to be cleared for a cloned test, it can be declared at "mustClear" in swagger
    if (productInfo.product === "rfv" && !isTestPlanEmpty()) {
      const properties =
        productInfo.swagger.definitions[plannedTest.testType]?.properties;
      cleanTestPlanConfig(plannedTest.config, properties);
    }
    setTestPlan([
      ...testPlan,
      { plannedTest: plannedTest, edit: true, toggleEditObj: {} },
    ]);
  }

  function isTestPlanEmpty() {
    return testPlan.length === 0;
  }

  function addSetupToTest(newSetup, testPlanIndex) {
    const plannedTest = testPlan[testPlanIndex].plannedTest;
    const maxSetupCount = getMaxSetupCount(
      productInfo.product,
      plannedTest.config.testType
    );
    if (plannedTest.config.setup.length < maxSetupCount) {
      plannedTest.config.setup.push(newSetup);
      updateTestPlanItem(testPlanIndex, plannedTest);
    }
  }

  function removeSetupFromTest(setupIndex, testPlanIndex) {
    const plannedTest = testPlan[testPlanIndex].plannedTest;
    const minSetupCount = getMinSetupCount(
      productInfo.product,
      plannedTest.config.testType
    );
    if (plannedTest.config.setup.length > minSetupCount) {
      plannedTest.config.setup.splice(setupIndex, 1);
      updateTestPlanItem(testPlanIndex, plannedTest);
    }
  }

  function addMultipleTestsToTestPlan(tests) {
    setTestPlan([...testPlan, ...tests]);
  }

  function updateTestPlanItem(index, plannedTest) {
    testPlan[index].plannedTest = plannedTest;
    setTestPlan([...testPlan]);
  }

  function updateNestedConfigToggleEdit(index, toggleEditObj) {
    testPlan[index].toggleEditObj = toggleEditObj;
    setTestPlan([...testPlan]);
  }

  function toggleTestPlanItemEdit(index) {
    testPlan[index].edit = !testPlan[index].edit;
    setTestPlan([...testPlan]);
  }

  function reorderTestPlan(startIndex, endIndex) {
    const [removed] = testPlan.splice(startIndex, 1);
    testPlan.splice(endIndex, 0, removed);
    setTestPlan([...testPlan]);
  }

  function deleteTest(index) {
    testPlan.splice(index, 1);
    setTestPlan([...testPlan]);
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    reorderTestPlan(result.source.index, result.destination.index);
  }

  const testPlanList = testPlan.map((testPlanItem, index) => (
    <Draggable key={index} draggableId={"draggable-" + index} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <PlannedTest
            productInfo={productInfo}
            plannedTest={testPlanItem.plannedTest}
            edit={testPlanItem.edit}
            index={index}
            toggleEdit={toggleTestPlanItemEdit}
            updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
            updateTestPlanItem={updateTestPlanItem}
            deleteTest={deleteTest}
            addSetupToTest={addSetupToTest}
            removeSetupFromTest={removeSetupFromTest}
          />
          <br />
        </div>
      )}
    </Draggable>
  ));

  let locationsFileUploadButton;
  if (productInfo.supportsLocationFileUpload) {
    locationsFileUploadButton = (
      <Row>
        <Col id="add-locations-button-col" className="mb-4">
          <AddLocationsButton
            updateTestLocations={updateTestLocations}
            isTestLocationsListEmpty={isTestLocationsListEmpty}
            testLocations={testLocations}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Card>
      <Card.Body>
        <h4>
          <FormattedMessage id="testplan.title" defaultMessage="Test Plan" />
          <br />
          <small>
            <FormattedMessage
              id="testplan.description"
              defaultMessage="Add tests to the test plan"
            />
          </small>
        </h4>
        <br />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="testPlan">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {locationsFileUploadButton}
                {testPlanList}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <AddTests
          productInfo={productInfo}
          addTestToTestPlan={addTestToTestPlan}
          addMultipleTestsToTestPlan={addMultipleTestsToTestPlan}
          isTestPlanEmpty={isTestPlanEmpty}
        />
      </Card.Body>
    </Card>
  );
}

function cloneLastItem(testPlan) {
  return JSON.parse(JSON.stringify(testPlan[testPlan.length - 1].plannedTest));
}

function cleanTestPlanConfig(config = {}, properties = {}) {
  for (const [key, value] of Object.entries(properties)) {
    if (value.type === "nestedObject") {
      cleanTestPlanConfig(config, value);
    } else if (config[key] && value.mustClear) {
      config[key] = "";
    }
  }
}

function AddTests({
  productInfo,
  addTestToTestPlan,
  addMultipleTestsToTestPlan,
  isTestPlanEmpty,
}) {
  if (productInfo.addMultiple) {
    return (
      <Row>
        <Col xs={{ span: 3, offset: 3 }}>
          <AddTestButton addTestToTestPlan={addTestToTestPlan} />
        </Col>
        <Col xs={{ span: 3 }}>
          <AddMultipleTestsButton
            productInfo={productInfo}
            addMultipleTestsToTestPlan={addMultipleTestsToTestPlan}
          />
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col>
          <AddTestButton
            productInfo={productInfo}
            addTestToTestPlan={addTestToTestPlan}
            isTestPlanEmpty={isTestPlanEmpty}
          />
        </Col>
      </Row>
    );
  }
}
