import { FormGroup, FormLabel, FormControl, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import DeployableToModal from "./DeployableToModal";
import { testTypes } from "../constants/testTypes";

export function SelectScfTest({
  index,
  selectValue,
  options,
  changeTestType,
  showDeployableTo,
  instrumentsList,
}) {
  const intl = useIntl();
  const optionList = makeOptionList(intl, options);
  let selectedTest;
  if (selectValue !== testTypes.fiberInspectionPcm) {
    selectedTest = selectValue.split(".")[0];
  } else {
    selectedTest = selectValue;
  }
  function addWavelengthAndChangeTestType(e) {
    const defaultWavelengths = {
      powermeter: ".1270.1577",
      opticalloss: ".1300",
    };
    const event = {
      target: {
        value: "",
      },
    };
    let selection = e.target.value;
    event.target.value = `${selection}${defaultWavelengths[selection] || ""}`;
    changeTestType(event);
  }

  return (
    <FormGroup controlId={"select-scf-test-" + index}>
      <FormLabel>
        <FormattedMessage
          id="selectOrDeleteTest.message"
          defaultMessage="Select a test type"
        />
      </FormLabel>
      <FormControl
        data-testid={`select-test-${selectValue} `}
        as="select"
        value={selectedTest}
        onChange={addWavelengthAndChangeTestType}
        autoFocus={true}
      >
        {optionList}
      </FormControl>
      {showDeployableTo && (
        <Form.Text className="text-muted">
          Click on the help icon to see the list of instruments this test can be
          deployed to
          <DeployableToModal
            title={"deployableToMessage"}
            instrumentsList={instrumentsList}
          />
        </Form.Text>
      )}
    </FormGroup>
  );
}

function makeOptionList(intl) {
  let reducedOptionsList = Object.values(testTypes).filter(
    (val) => val !== "OTDR"
  );

  const optionList = reducedOptionsList.map((optionValue, index) => (
    <option key={index} value={optionValue}>
      {intl.formatMessage({ id: optionValue })}
    </option>
  ));
  return optionList;
}
