import { Fragment, useState, useEffect } from "react";

import { Button, Card, ButtonGroup, Navbar, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { useFlags } from "./FeatureFlags";
import { getLoginHref, getMyUser } from "../stratasync/api.js";

export default function LoginBlock(props) {
  if (props.accessToken) {
    return (
      <UserInfo
        ssServer={props.ssServer}
        accessToken={props.accessToken}
        logout={props.logout}
      />
    );
  } else {
    return <LoginForm ssServer={props.ssServer} setServer={props.setServer} />;
  }
}

function LoginForm({ ssServer, setServer }) {
  const href = getLoginHref(ssServer);
  const navbar = useFlags().navbar;
  if (navbar) {
    return (
      <Form inline className="mr-sm-2">
        <Button
          variant="outline-primary"
          id="login"
          href={href}
          className="mr-sm-2"
        >
          <FormattedMessage id="loginbutton.message" defaultMessage="Login" />
        </Button>{" "}
        <ButtonGroup vertical size="sm" className="mr-sm-2">
          <Button
            variant="outline-primary"
            active={ssServer === "prod_us"}
            onClick={() => setServer("prod_us")}
          >
            US
          </Button>
          <Button
            variant="outline-primary"
            active={ssServer === "prod_eu"}
            onClick={() => setServer("prod_eu")}
          >
            EU
          </Button>
        </ButtonGroup>
      </Form>
    );
  } else {
    return (
      <Form>
        <Button variant="outline-primary" id="login" href={href}>
          <FormattedMessage id="loginbutton.message" defaultMessage="Login" />
        </Button>
        <br />
        <ButtonGroup size="sm">
          <Button
            variant="outline-primary"
            active={ssServer === "prod_us"}
            onClick={() => setServer("prod_us")}
          >
            US
          </Button>
          <Button
            variant="outline-primary"
            active={ssServer === "prod_eu"}
            onClick={() => setServer("prod_eu")}
          >
            EU
          </Button>
        </ButtonGroup>
      </Form>
    );
  }
}

function UserInfo(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let isCanceled = false;
    async function getUser() {
      const user = await getMyUser(props.ssServer, props.accessToken);
      if (!isCanceled) {
        setUser(user);
      }
    }
    getUser();

    return () => {
      isCanceled = true;
    };
  }, [props.ssServer, props.accessToken]);

  var nameStr = <span>&nbsp;</span>;
  var accountIdStr = <span>&nbsp;</span>;
  var techIdStr = <span>&nbsp;</span>;
  if (user) {
    nameStr = user.firstName + " " + user.lastName;
    accountIdStr = "Account ID: " + user.accountId;
    techIdStr = "Tech ID: " + user.techId;
  }

  const navbar = useFlags().navbar;
  if (navbar) {
    return (
      <Fragment>
        <Navbar.Text pullright="true">{nameStr}</Navbar.Text>
        <Navbar.Text pullright="true">{accountIdStr}</Navbar.Text>
        <Navbar.Text pullright="true">{techIdStr}</Navbar.Text>
        <Form pullright="true">
          <Button variant="outline-primary" onClick={props.logout}>
            <FormattedMessage
              id="logoutbutton.message"
              defaultMessage="Logout"
            />
          </Button>
        </Form>
      </Fragment>
    );
  } else {
    return (
      <Card>
        <Card.Header>{nameStr}</Card.Header>
        <Card.Body>
          {accountIdStr}
          <br />
          {techIdStr}
          <br />
          <Button variant="outline-primary" onClick={props.logout}>
            <FormattedMessage
              id="logoutbutton.message"
              defaultMessage="Logout"
            />
          </Button>
        </Card.Body>
      </Card>
    );
  }
}
