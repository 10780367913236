const testTypes4000 = {
  fiberInspection: {
    testType: "fiberInspection",
    referenceInfo: [
      { key: "fiberId", value: "" },
      {
        key: "fiberNumber",
        value: "",
      },
    ],
  },
  fiberContinuity: {
    testType: "fiberContinuity",
    referenceInfo: [
      { key: "fiberId", value: "" },
      {
        key: "fiberNumber",
        value: "",
      },
    ],
  },
  OTDR: {
    testType: "OTDR",
    referenceInfo: [
      { key: "fiberId", value: "" },
      {
        key: "fiberNumber",
        value: "",
      },
    ],
  },
  bidirIlOrl: {
    testType: "bidirIlOrl",
    referenceInfo: [
      { key: "fiberId", value: "" },
      {
        key: "fiberNumber",
        value: "",
      },
    ],
  },
  fcompPro: {
    testType: "fcompPro",
    referenceInfo: [
      { key: "fiberId", value: "" },
      {
        key: "fiberNumber",
        value: "",
      },
    ],
  },
  manualStep: {
    testType: "manualStep",
    referenceInfo: [],
  },
};

export default testTypes4000;
