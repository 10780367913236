const testTypesOneExpertDSP = {
  Ethernet: {
    testType: "Ethernet",
    launchableType: "ethernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "Fiber Inspection": {
    testType: "Fiber Inspection",
    launchableType: "fiberInspection",
    referenceInfo: [],
  },
  "OneCheck Ethernet": {
    testType: "OneCheck Ethernet",
    launchableType: "oneCheckEthernet",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck Fiber": {
    testType: "OneCheck Fiber",
    launchableType: "oneCheckFiber",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
  "OneCheck WiFi": {
    testType: "OneCheck WiFi",
    launchableType: "oneCheckWiFi",
    referenceInfo: [],
  },
  "WiFi Expert": {
    testType: "WiFi Expert",
    launchableType: "wiFiExpert",
    referenceInfo: [],
  },
  "Optical Power Meter": {
    testType: "Optical Power Meter",
    launchableType: "powerMeter",
    referenceInfo: [],
  },
  "WiFi Scan": {
    testType: "WiFi Scan",
    launchableType: "wifiScan",
    referenceInfo: [],
  },
  OneCheck: {
    testType: "OneCheck",
    launchableType: "onecheck",
    referenceInfo: [],
  },
  "Ingress Scan": {
    testType: "Ingress Scan",
    launchableType: "ingressscanmode",
    referenceInfo: [],
  },
  "Channel Check": {
    testType: "Channel Check",
    launchableType: "channelcheck",
    referenceInfo: [],
  },
  "DOCSIS Check": {
    testType: "DOCSIS Check",
    launchableType: "docsischeck",
    referenceInfo: [],
  },
  Spectrum: {
    testType: "Spectrum",
    launchableType: "spectrummode",
    referenceInfo: [],
  },
  "Quick Check": {
    testType: "Quick Check",
    launchableType: "twopointalignment",
    referenceInfo: [],
  },
  "Cable Fault Finder": {
    testType: "Cable Fault Finder",
    launchableType: "tdrmode",
    referenceInfo: [],
  },
  "Cable Fault Finder - Drop Check": {
    testType: "Cable Fault Finder - Drop Check",
    launchableType: "tdrmode.dropcheck",
    referenceInfo: [],
  },
  "Cable Fault Finder - Cable Length": {
    testType: "Cable Fault Finder - Cable Length",
    launchableType: "tdrmode.cablelength",
    referenceInfo: [],
  },

  "Manual Step": {
    testType: "Manual Step",
    launchableType: "manualStep",
    referenceInfo: [
      {
        key: "Configuration Profile (filename)",
        value: "",
      },
    ],
  },
};

export default testTypesOneExpertDSP;
