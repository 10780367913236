import JobInformation from "./JobInformation.js";
import AttributesPlan from "./AttributesPlan.js";
import TestPlan from "./TestPlan.js";
import { isSmartClassFiber } from "../products/productInfo";

export default function JobForm({
  job,
  setJob,
  setTestPlan,
  setAttributesPlan,
  productInfo,
  updateTestLocations,
  isTestLocationsListEmpty,
  testLocations,
}) {
  return (
    <>
      <br />
      <JobInformation job={job} setJob={setJob} productInfo={productInfo} />
      {(productInfo.isOnx || isSmartClassFiber(productInfo.product)) && <br />}
      {(productInfo.isOnx || isSmartClassFiber(productInfo.product)) && (
        <AttributesPlan
          productInfo={productInfo}
          attributesPlan={job.attributesPlan}
          setAttributesPlan={setAttributesPlan}
        />
      )}
      <br />
      <TestPlan
        productInfo={productInfo}
        testPlan={job.testPlan}
        setTestPlan={setTestPlan}
        updateTestLocations={updateTestLocations}
        isTestLocationsListEmpty={isTestLocationsListEmpty}
        testLocations={testLocations}
      />
      <br />
    </>
  );
}
