export const testTypes = {
  fiberInspection: "fiberInspection",
  fiberInspectionPcm: "fiberInspection.pcm",
  powermeter: "powermeter",
  opticalloss: "opticalloss",
  truepon: "truepon",
  otdr: "OTDR",
  manualStep: "Manual Step",
};

export const trueponTestTypes = {
  gpon: "gpon",
  xgspon: "xgspon",
  gponXgspon: "gponXgspon",
};
