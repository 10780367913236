import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export function EmptyLocationsDialog({
  handleCancel,
  openDialog,
  warningText,
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Test Locations List is Empty!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
