import { render } from "@testing-library/react";
import { Component } from "react";
import { useState } from "react";
import { Button, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import ReactGA from "react-ga";

import convertToCDM, {
  convertToOneExpertCDM,
  convertToTamplateCDM,
} from "../commonDataModel/commonDataModel.js";
import getProductInfo, {
  isSmartClassFiber,
  productKeys,
} from "../products/productInfo.js";
import { sendJobToStrataSync } from "../stratasync/api.js";
import ActionButtons from "./ActionButtons.js";
import JobForm from "./JobForm.js";
import ProductSelector from "./ProductSelector.js";
import { initJobAttributes } from "./utilities.js";

// temporarily import, will be removed after RF Vsion 2.9.x
class Job extends Component {
  constructor(props) {
    super(props);
    this.setJob = this.setJob.bind(this);
    this.setTestPlan = this.setTestPlan.bind(this);
    this.setAttributesPlan = this.setAttributesPlan.bind(this);
    this.saveJobONX = this.saveJobONX.bind(this);
    this.saveJobTemplate = this.saveJobTemplate.bind(this);
    this.saveJob = this.saveJob.bind(this);
    this.loadJob = this.loadJob.bind(this);
    this.sendJobToStrataSync = this.sendJobToStrataSync.bind(this);
    this.setProduct = this.setProduct.bind(this);
    this.loadONXJob = this.loadONXJob.bind(this);
    this.parseAttributes = this.parseAttributes.bind(this);
    this.parseTests = this.parseTests.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.updateTestLocations = this.updateTestLocations.bind(this); //currently used only by SCF
    this.isTestLocationsListEmpty = this.isTestLocationsListEmpty.bind(this);
    this.parseTestLocations = this.parseTestLocations.bind(this);
    this.state = getState("5800");
  }
  render() {
    const productInfo = this.state.productInfo;
    return (
      <form>
        <ProductSelector
          product={this.state.product}
          setProduct={this.setProduct}
        />
        <JobForm
          job={this.state.job}
          productInfo={productInfo}
          setJob={this.setJob}
          setTestPlan={this.setTestPlan}
          setAttributesPlan={this.setAttributesPlan}
          updateTestLocations={this.updateTestLocations}
          isTestLocationsListEmpty={this.isTestLocationsListEmpty}
          testLocations={this.state.testLocations}
        />
        <ActionButtons
          loadJob={this.loadONXJob}
          saveJob={this.saveJob}
          saveJobONX={this.saveJobONX}
          validateFields={this.validateFields}
          saveJobTemplate={this.saveJobTemplate}
          ssServer={this.props.ssServer}
          accessToken={this.props.accessToken}
          sendJobToStrataSync={this.sendJobToStrataSync}
          productInfo={productInfo}
          isTestLocationsListEmpty={this.isTestLocationsListEmpty}
        />
      </form>
    );
  }

  setJob(job) {
    this.setState({ job: job });
  }

  setTestPlan(testPlan) {
    this.setState((prevState) => ({
      job: { ...prevState.job, testPlan: testPlan },
    }));
  }

  setAttributesPlan(attributesPlan) {
    this.setState((prevState) => ({
      job: { ...prevState.job, attributesPlan: attributesPlan },
    }));
  }

  loadONXJob(job) {
    if (job.product) {
      this.loadJob(job);
      return;
    } else if (job.jobManagerProduct) {
      delete job.cdmVersion;
      const product = job.jobManagerProduct || "onx580";
      delete job.jobManagerProduct;
      const productInfo = getProductInfo(product);
      let testLocations = [];
      job.jobId = job.cdm[0].workflow.workOrderId;
      job.technicianId = job.cdm[0].workflow.techInfo.techId;
      job.Date = ["", ""];
      job.attributesPlan = this.parseAttributes(
        job.cdm[0].workflow.jobManagerAttributes
      );
      job.testPlan = this.parseTests(
        job.cdm[0].tests,
        productInfo.testTypes,
        productInfo.product
      );
      // SmartClass Fiber tests have one locations list for all the tests.
      if (isSmartClassFiber(productInfo.product)) {
        testLocations = this.parseTestLocations(job.cdm[0].tests);
      }
      delete job.cdm;
      delete job.jobManagerProduct;
      ReactGA.event({
        category: "User",
        action: "Load Job",
      });
      this.setState({
        job: job,
        product: product,
        productInfo: productInfo,
        testLocations: testLocations,
      });
      this.updateTestLocations(testLocations);
    } else {
      this.loadJob(job);
    }
  }

  parseTestLocations(tests) {
    // This is used only by SmartClass Fiber as of 10/01/2021. All the tests have the same
    // locations list.
    if (tests.length > 0) {
      return tests[0].testLocations.map((testLocation) => testLocation.label);
    }
    return [];
  }

  parseAttributes(attributes) {
    var attributesPlan = [];
    for (var key of Object.keys(attributes)) {
      var jobAttributes = initJobAttributes();
      jobAttributes.label = attributes[key].label;
      jobAttributes.defaultValue = attributes[key].value;
      jobAttributes.uniqueId = key;
      if (
        attributes[key].regExp === ".*" &&
        attributes[key].validValues.length === 0
      ) {
        jobAttributes.validationType = "None";
      } else if (
        attributes[key].regExp !== ".*" &&
        attributes[key].validValues.length === 0
      ) {
        jobAttributes.validationType = "Regular expression";
        jobAttributes.validValueList = attributes[key].regExp;
      } else if (
        attributes[key].regExp === ".*" &&
        attributes[key].validValues.length !== 0
      ) {
        jobAttributes.validationType = "Valid Value List";
        jobAttributes.validValueList = attributes[key].validValues.join();
      }
      attributesPlan.push({ edit: true, jobAttributes: jobAttributes });
    }
    return attributesPlan;
  }
  parseTests(tests, testTypes, product) {
    var testPlan = [];
    tests.forEach(function (item, index) {
      var plannedTest;
      if (item.type === "manualStep") {
        plannedTest = JSON.parse(JSON.stringify(testTypes["Manual Step"]));
      } else if (product === productKeys.scf) {
        plannedTest = JSON.parse(JSON.stringify(testTypes[item.type]));
      } else {
        plannedTest = JSON.parse(JSON.stringify(testTypes[item.label]));
      }
      if (product === productKeys.scf) {
        plannedTest.config = item.configuration;
      } else if (product === productKeys.rfv) {
        let tempConfig = {};
        parseRfvConfig(item.configuration, tempConfig);
        plannedTest.config = tempConfig;
        plannedTest.configValidation = {};
        for (const key of Object.keys(tempConfig)) {
          plannedTest.configValidation[key] = true;
        }
      } else {
        plannedTest.config = null;
      }
      plannedTest.configProfile = "";
      if (item.type === "manualStep") plannedTest.testLabel = item.label;
      else plannedTest.testLabel = "";
      if (item.testLocations) {
        plannedTest.locations = [];
        item.testLocations.forEach(function (item, index) {
          plannedTest.locations.push({ location: item.label });
        });
      }

      if (plannedTest.referenceInfo[0]) {
        plannedTest.referenceInfo[0].value = item.configuration.profile;
      }
      plannedTest.procedures = item.proceduresUrl;
      testPlan.push({ plannedTest: plannedTest, edit: true });
    });
    return testPlan;
  }

  validateFields() {
    var isValid = true;
    var job = this.state.job;
    job.attributesPlan?.forEach((item) => {
      isValid &= item.jobAttributes["isRegexValid"];
    });
    // RF Vision input constaint, will only exist for version 2.9.x
    if (this.state.product === productKeys.rfv) {
      isValid &= validateRFVConfigInputs(job.testPlan);
    }
    job.testPlan.forEach((ts) => {
      if (ts.plannedTest?.configValidation) {
        for (const value of Object.values(ts.plannedTest.configValidation)) {
          isValid &= value;
        }
      }
    });
    return isValid;
  }

  loadJob(job) {
    delete job.cdmVersion;
    delete job.cdm;
    const product = job.product || "5800";
    delete job.product;
    job.testPlan = addEdits(job.testPlan);
    const productInfo = getProductInfo(product);
    ReactGA.event({
      category: "User",
      action: "Load Job",
    });
    this.setState({ job: job, product: product, productInfo: productInfo });
  }
  saveJobONX() {
    ReactGA.event({
      category: "User",
      action: "Save Job",
    });
    const { productInfo, testLocations } = this.state;
    let job = Object.assign({}, this.state.job);
    job.testPlan = stripEdits(job.testPlan);
    job.testPlan = addSubTests(job.testPlan);
    // this is specific to SmartClass Fiber. All tests have
    // the same list of locations.
    if (testLocations.length > 0) {
      this.addLocationsToTestPlan(job);
    }
    const res = {
      cdmVersion: "2.1",
      cdm: [convertToOneExpertCDM(job, productInfo.ssAssets)],
      jobManagerProduct: this.state.product,
    };
    const filename = "workflow.json";
    var fileDownload = require("js-file-download");
    var jobJSON = JSON.stringify(res, null, "\t");
    fileDownload(jobJSON, filename);
  }
  saveJobTemplate(tamplateName) {
    ReactGA.event({
      category: "User",
      action: "Save Job",
    });
    const { productInfo, testLocations } = this.state;
    let job = Object.assign({}, this.state.job);
    job.testPlan = stripEdits(job.testPlan);
    job.testPlan = addSubTests(job.testPlan);
    // this is specific to SmartClass Fiber. All tests have
    // the same list of locations.
    if (testLocations.length > 0) {
      this.addLocationsToTestPlan(job);
    }
    const res = {
      cdmVersion: "2.1",
      cdm: [convertToTamplateCDM(job, tamplateName, productInfo.ssAssets)],
      jobManagerProduct: this.state.product,
    };
    const filename = "template_" + tamplateName + ".json";
    var fileDownload = require("js-file-download");
    var jobJSON = JSON.stringify(res, null, "\t");
    fileDownload(jobJSON, filename);
  }

  saveJob() {
    ReactGA.event({
      category: "User",
      action: "Save Job",
    });
    const { productInfo } = this.state;
    let job = Object.assign({}, this.state.job);
    job.testPlan = stripEdits(job.testPlan);
    job.testPlan = addSubTests(job.testPlan);

    const commonDataModel = {
      cdmVersion: "2.1",
      cdm: [convertToCDM(job, productInfo.ssAssets)],
    };
    const output = Object.assign({}, job, commonDataModel, {
      product: this.state.product,
    });
    const filename = "job_" + job.jobNumber + ".job.json";
    var fileDownload = require("js-file-download");
    var jobJSON = JSON.stringify(output, null, "\t");
    fileDownload(jobJSON, filename);
  }

  async sendJobToStrataSync(techId) {
    const { ssServer, accessToken } = this.props;
    const { productInfo, testLocations } = this.state;
    ReactGA.event({
      category: "User",
      action: "Send Job to StrataSync",
    });
    var moment = require("moment");
    let job = Object.assign({}, this.state.job);
    job.testPlan = stripEdits(job.testPlan);
    // this is specific to SmartClass Fiber. All tests have
    // the same list of locations.
    if (testLocations.length > 0) {
      this.addLocationsToTestPlan(job);
    }
    var commonDataModel;
    if (
      productInfo.product === "onx580" ||
      productInfo.product === "onxcatv" ||
      productInfo.product === "nsc100" ||
      productInfo.product === "onx220" ||
      productInfo.product === productKeys.scf ||
      productInfo.product === "rfv"
    ) {
      commonDataModel = convertToOneExpertCDM(job, productInfo.ssAssets);
      commonDataModel.cdmVersion = "2.1";
    } else {
      commonDataModel = convertToCDM(job, productInfo.ssAssets);
      commonDataModel.workflow.date = moment().format();
    }
    commonDataModel.workflow.techInfo.techId = techId;
    const sent = await sendJobToStrataSync(
      ssServer,
      accessToken,
      commonDataModel
    );
    return sent;
  }

  setProduct(product) {
    this.setState(getState(product));
  }

  updateTestLocations(locations) {
    this.setState({ testLocations: locations });
  }

  addLocationsToTestPlan(job) {
    job.testPlan.forEach((test) => {
      test.locations = this.state.testLocations.map((location) => {
        return { location: location };
      });
    });
  }

  isTestLocationsListEmpty() {
    return this.state.testLocations.length === 0;
  }
}

// Limitation in r2.9.x, will be totally remove in the next release
// only one test type allowed besides manual test
function validateRFVConfigInputs(testplan) {
  var isValid = true;
  let testTypeValid = true;
  let siteIdValid = true;
  let pathIdValid = true;

  let testTypesSet = new Set(
    testplan
      .filter((test) => test.plannedTest.testType !== "Manual Step")
      .map((test) => test.plannedTest.testType)
  );

  // microwave and anneta tests are not allowed in one job
  if (testTypesSet.size > 1) {
    testTypeValid = false;
    isValid &= testTypeValid;
  }
  // only same siteId is allowed
  let siteIdSet = new Set(
    testplan
      .filter((test) => test.plannedTest.testType === "Antenna Alignment")
      .map((test) => test.plannedTest.config.siteId)
  );
  if (siteIdSet.size > 1) {
    siteIdValid = false;
    isValid &= siteIdValid;
  }
  // only same pathId is allowed
  let pathIdSet = new Set(
    testplan
      .filter((test) => test.plannedTest.testType === "Microwave Alignment")
      .map((test) => test.plannedTest.config.pathId)
  );
  if (pathIdSet.size > 1) {
    pathIdValid = false;
    isValid &= pathIdValid;
  }

  if (!isValid) {
    render(
      <ValidationModal
        testTypeValid={testTypeValid}
        siteIdValid={siteIdValid}
        pathIdValid={pathIdValid}
      />
    );
  }
  return isValid;
}

// temporary function for RF Vision 2.9.x, will be removed in the next release
function ValidationModal(props) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Save Job Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {!props.testTypeValid && (
            <ListGroupItem>
              <p className="text-danger h5">
                *Job shall not contain both "Antenna Alignment" test and
                "Microwave Alignment" test.
              </p>
            </ListGroupItem>
          )}
          {!props.siteIdValid && (
            <ListGroupItem>
              <p className="text-danger h5">
                *Any "Site Id" values MUST be the same.
              </p>
            </ListGroupItem>
          )}
          {!props.pathIdValid && (
            <ListGroupItem>
              <p className="text-danger h5">
                *Any "Path Id" values MUST be the same.
              </p>
            </ListGroupItem>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function parseRfvConfig(item, config) {
  for (const prop in item) {
    if (typeof item[prop] === "object" && item[prop] !== null) {
      parseRfvConfig(item[prop], config);
    } else {
      config[prop] = item[prop];
    }
  }
}

function getState(product) {
  const productInfo = getProductInfo(product);
  const job = JSON.parse(JSON.stringify(productInfo.initialJob));
  const state = {
    job: job,
    product: product,
    productInfo: productInfo,
    testLocations: [],
  };
  return state;
}

export function stripEdits(testPlan) {
  return testPlan.map((testPlanItem) => testPlanItem.plannedTest);
}

export function addEdits(testPlan) {
  return testPlan.map((plannedTest) => ({
    plannedTest: plannedTest,
    edit: false,
  }));
}

export function addSubTests(testPlan) {
  return testPlan.map((plannedTest) => {
    if (plannedTest.testType === "manualStep") {
      plannedTest.subTypeInfo = [
        { key: "testLabel", value: plannedTest.testLabel },
      ];
    }
    return plannedTest;
  });
}

export default Job;
