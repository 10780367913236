import { useState, useEffect } from "react";

export default function useSimulateDelay(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (value) {
      simulateDelay().then(() => {
        setValue(defaultValue);
      });
    }
  }, [value, defaultValue]);

  function setDisabled(value) {
    setValue(value);
  }
  return [value, setDisabled];
}

function simulateDelay() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}
