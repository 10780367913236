import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function DeployableToModal({ title, instrumentsList }) {
  const [show, setShow] = useState(false);
  const instruments = [];
  instrumentsList.forEach((model, index) => {
    instruments.push(
      <ListGroupItem key={"model" + index}>{model}</ListGroupItem>
    );
  });
  return (
    <React.Fragment>
      <span className="h5">
        <Button
          variant="link"
          size="sm"
          onClick={() => setShow(true)}
          aria-label="Component Help Button"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
        <Modal size="lg" show={show} onHide={() => setShow()}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id={title} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>{instruments}</ListGroup>
          </Modal.Body>
        </Modal>
      </span>
    </React.Fragment>
  );
}
export default DeployableToModal;
