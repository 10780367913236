import { useCallback } from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { isWavelengthRange, validateValue } from "./utilities";
import HelpModal from "./HelpModal.js";
import { ToggleEditButton } from "./SelectOrDeleteTest.js";

let toggleEditObj = {};

export function TestSetupInput({
  value,
  label,
  setupIndex,
  setupFields,
  updateConfig,
  removeSetupFromTest,
  updateNestedConfigToggleEdit,
  testPlanIndex,
  configSchema,
  testType,
}) {
  function handleChange(e, field, setupIndex) {
    const floatVal = parseFloat(e.target.value);
    // make sure that a string that starts with a number is not partially converted to float
    if (
      floatVal.toString().length === e.target.value.length &&
      !isNaN(floatVal)
    ) {
      value[setupIndex][`${field}`] = floatVal;
    } else {
      value[setupIndex][`${field}`] = e.target.value;
    }
    updateConfig(label, value);
  }

  let removeSetupFromTestButton;
  if (isWavelengthRange(testType)) {
    removeSetupFromTestButton = (
      <RemoveSetupFromTestButton
        removeSetupFromTest={removeSetupFromTest}
        testPlanIndex={testPlanIndex}
        setupIndex={setupIndex}
      />
    );
  }

  const validateInput = useCallback(
    (input, field) => {
      const inputProperties = configSchema[field];
      const inputType = inputProperties.type;
      const min = inputProperties.minimum;
      const max = inputProperties.maximum;
      const pattern = inputProperties.pattern;
      const isInputValid = validateValue(input, inputType, min, max, pattern);
      return isInputValid;
    },
    [configSchema]
  );

  let setupFieldItems = Object.keys(setupFields).map((field, fieldIndex) => {
    if (configSchema[field].readOnly) {
      return (
        <FormGroup
          key={field + testPlanIndex + setupIndex + fieldIndex}
          controlId={field + setupIndex + fieldIndex}
        >
          <FormLabel>
            <FormattedMessage id={field} />
          </FormLabel>
          <FormControl readOnly type="text" value={setupFields[field]} />
        </FormGroup>
      );
    } else {
      const inputProperties = configSchema[field];
      const isValid = validateInput(setupFields[field], field);
      return (
        <FormGroup
          key={field + testPlanIndex + setupIndex + fieldIndex}
          controlId={field + setupIndex + fieldIndex}
        >
          <FormLabel>
            <FormattedMessage id={field} />
          </FormLabel>
          <HelpModal title={field} property={inputProperties} />
          <FormControl
            data-testid={field + setupIndex + fieldIndex}
            type="number"
            step={0.1}
            precision={1}
            value={setupFields[field]}
            onChange={(e) => handleChange(e, field, setupIndex)}
          />
          <Form.Text className={isValid ? "text-success" : "text-danger"}>
            <FormattedMessage
              id={isValid ? "Input is valid" : "Input not valid"}
            />
          </Form.Text>
        </FormGroup>
      );
    }
  });

  if (!toggleEditObj[`${testPlanIndex}${setupIndex}`]) {
    setupFieldItems = (
      <Row style={{ textAlign: "left" }}>
        <Col xs={11}>
          <strong>
            <FormattedMessage
              id={`${label}.${setupFields["wavelengthNm"]}`}
              defaultMessage="Thresholds - {wavelength} nm"
              values={{
                wavelength: setupFields["wavelengthNm"],
              }}
            />
          </strong>
        </Col>
      </Row>
    );
  }

  return (
    <Card key={label + testPlanIndex + setupIndex} className="pb-2 mt-4 mb-2">
      <Card.Body>
        <Row>
          <Col xs={10}>{setupFieldItems}</Col>
          <Col xs={2}>
            <ToggleEditButton
              toggleEdit={() => {
                toggleEdit(
                  updateNestedConfigToggleEdit,
                  testPlanIndex,
                  `${testPlanIndex}${setupIndex}`,
                  toggleEditObj
                );
              }}
              index={`${testPlanIndex}${setupIndex}`}
            />
            {removeSetupFromTestButton}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function toggleEdit(
  updateNestedConfigToggleEdit,
  testPlanIndex,
  configIndex,
  nestedObj
) {
  nestedObj[configIndex] = nestedObj[configIndex] ? false : true;
  updateNestedConfigToggleEdit(testPlanIndex, nestedObj);
}

export function RemoveSetupFromTestButton(props) {
  function removeSetup() {
    props.removeSetupFromTest(props.setupIndex, props.testPlanIndex);
  }
  return (
    <button
      data-testid={`remove-setup-${props.setupIndex}${props.testPlanIndex}`}
      type="button"
      className="close"
      aria-hidden="true"
      onClick={removeSetup}
    >
      &times;
    </button>
  );
}
