import { FormGroup, Form, FormLabel, FormControl } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function MethodsProceduresInput(props) {
  const { methodsProceduresPath, isOnx } = props.productInfo;
  function handleChange(e) {
    var value = e.target.value;
    if (value) {
      value = methodsProceduresPath + value;
    }
    props.updateMethodsProceduresFile(value);
  }

  if (!methodsProceduresPath && !isOnx) {
    return null;
  }
  const value = props.value.replace(methodsProceduresPath, "");
  const helpText = makeHelpText(
    value,
    methodsProceduresPath.replace("/user/", ""),
    isOnx
  );
  const label = isOnx
    ? "methodsProceduresInput.messageONX"
    : "methodsProceduresInput.message";
  return (
    <FormGroup controlId={"methodsProceduresInput" + props.testIndex}>
      <FormLabel>
        <FormattedMessage
          id={label}
          defaultMessage="Methods & Procedures File"
        />
      </FormLabel>
      <FormControl
        type="text"
        value={value}
        onChange={handleChange}
        name="methodsProceduresInput"
      />
      <Form.Text className="text-muted">{helpText}</Form.Text>
    </FormGroup>
  );
}

function makeHelpText(value, basePath, isOnx) {
  const baseHelp = (
    <FormattedMessage
      id={
        isOnx
          ? "Link that will open when the tech clicks the guide button."
          : "methodsProceduresInput.helpBase"
      }
      defaultMessage={
        isOnx
          ? "Link that will open when the tech clicks the guide button."
          : "File name of a document that will open when the tech clicks" +
            " the guide button. "
      }
    />
  );
  if (!value || isOnx) {
    return baseHelp;
  } else {
    return (
      <span>
        {baseHelp}
        <FormattedMessage
          id="methodsProceduresInput.helpFileLocation"
          defaultMessage={
            "This file should be in the following location on the" +
            " instrument: {filepath}"
          }
          values={{
            filepath: basePath + value,
          }}
        />
      </span>
    );
  }
}
