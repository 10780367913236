import ConfigInput from "./ConfigInput";

export default function ConfigInputs({
  testPlanIndex,
  config,
  updateConfig,
  updateNestedConfigToggleEdit,
  configSchema,
  testIndex,
  addSetupToTest,
  removeSetupFromTest,
}) {
  var configInputs = [];
  if (config) {
    var localConfig = Object.assign({}, config);
    const testType = localConfig.testType;
    delete localConfig.testType;
    Object.entries(localConfig).forEach(([label, value], index) => {
      configInputs.push(
        <ConfigInput
          testPlanIndex={testPlanIndex}
          key={index}
          label={label}
          value={value}
          updateConfig={updateConfig}
          updateNestedConfigToggleEdit={updateNestedConfigToggleEdit}
          configSchema={configSchema}
          testIndex={testIndex}
          testType={testType}
          addSetupToTest={addSetupToTest}
          removeSetupFromTest={removeSetupFromTest}
        />
      );
    });
  }
  return configInputs;
}
