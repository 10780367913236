import { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { productKeys } from "./utilities";

class AddTestButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.addTestToTestPlan();
  }

  render() {
    const addTestButton = (
      <Button
        variant="outline-primary"
        id="add-test"
        onClick={() => {
          this.handleClick();
        }}
      >
        <FormattedMessage
          id="addtestbutton.message"
          defaultMessage="Add Test"
        />
      </Button>
    );

    // Restrict the number of tests per job to one if it is a SmartClass Fiber job.
    if (
      this.props.productInfo?.product === productKeys.scf &&
      !this.props.isTestPlanEmpty()
    ) {
      return null;
    } else {
      return addTestButton;
    }
  }
}

export default AddTestButton;
