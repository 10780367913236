import { createContext, useContext } from "react";
import Cookies from "universal-cookie";

export const Flags = createContext({
  enableProducts: { enableSCF: true },
});

export default function FlagProvider({ children }) {
  const cookies = new Cookies();

  function flagFromCookie(cookieName) {
    const value = cookies.get(cookieName) === "true";
    cookies.set(cookieName, value);
    return value;
  }
  const flagValue = {
    enableProducts: {
      enableSCF: flagFromCookie("enableSCF"), // Flag to enable SmartClass Fiber
    },

    navbar: flagFromCookie("navbar"), //Flag to use a navbar or not
  };
  return <Flags.Provider value={flagValue}>{children}</Flags.Provider>;
}

export function useFlags() {
  return useContext(Flags);
}
