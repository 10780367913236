import { FormattedMessage, useIntl } from "react-intl";

export const productKeys = {
  nsc100: "nsc100",
  onxcatv: "onxcatv",
  onx220: "onx220",
  onx580: "onx580",
  rfv: "rfv",
  scf: "scf",
};

const scfTestReportLabels = {
  fiberInspection: "Fiber Inspection",
  opticalloss: "Optical Loss",
  powermeter: "Power Meter",
  truepon: "TruePON",
};

export function makeFirstTest(productInfo) {
  const testType = testOptions(productInfo.testTypes)[0];
  return initPlannedTest(productInfo, testType);
}

function initPlannedTest(productInfo, testSelection) {
  const testTypes = productInfo.testTypes;
  var plannedTest = JSON.parse(JSON.stringify(testTypes[testSelection]));
  plannedTest.config = makeConfig(plannedTest.testType, productInfo);
  plannedTest.testLabel = getTestLabel(
    productInfo.product,
    plannedTest.testType
  );
  if (
    [
      productKeys.onxcatv,
      productKeys.onx220,
      productKeys.onx580,
      productKeys.nsc100,
      productKeys.scf,
    ].includes(productInfo.product)
  ) {
    plannedTest.configProfile = "";
    plannedTest.locations = [];
  }
  if (productInfo.product === productKeys.rfv) {
    plannedTest.configProfile = "";
    plannedTest.configValidation = validConfig(
      plannedTest.testType,
      productInfo
    );
  }

  return plannedTest;
}

function getTestLabel(product, testType) {
  if (product === productKeys.scf) {
    const [testTypeWithoutWavelengths] = [...testType.split(".")];
    return scfTestReportLabels[testTypeWithoutWavelengths];
  }
  return "";
}

function updateDynamicConfig(testPlan, label, value) {
  if (isRFVTest(testPlan.testType)) {
    return updateRFVTestPlan(testPlan, label, value);
  }
}

function updateRFVTestPlan(testPlan, label, value) {
  let condfig = testPlan.config;
  let configValidation = testPlan.configValidation;
  if (testPlan.testType === "Antenna Alignment") {
    if (label === "azimuthRequired") {
      if (value === "No") {
        delete condfig.azimuth;
        delete condfig.azimuthThreshold;
        delete configValidation.azimuth;
        delete configValidation.azimuthThreshold;
      } else {
        const configFields = Object.entries(condfig);
        const index = configFields.findIndex(
          ([key]) => key === "azimuthRequired"
        );
        const reconstructedFields = Object.fromEntries([
          ...configFields.slice(0, index),
          configFields[index],
          ...[
            ["azimuth", ""],
            ["azimuthThreshold", ""],
          ],
          ...configFields.slice(index + 1),
        ]);
        testPlan.config = reconstructedFields;
        configValidation.azimuth = true;
        configValidation.azimuthThreshold = true;
      }
    }
  }

  if (testPlan.testType === "Microwave Alignment") {
    if (label === "azimuthRequired") {
      if (value === "No") {
        delete condfig.azimuthThreshold;
        delete configValidation.azimuthThreshold;
      } else {
        const configFields = Object.entries(condfig);
        const index = configFields.findIndex(
          ([key]) => key === "azimuthRequired"
        );
        const reconstructedFields = Object.fromEntries([
          ...configFields.slice(0, index),
          configFields[index],
          ...[["azimuthThreshold", ""]],
          ...configFields.slice(index + 1),
        ]);
        testPlan.config = reconstructedFields;
        configValidation.azimuthThreshold = true;
      }
    }
  }

  return testPlan;
}

function initJobAttributes() {
  var jobAttributes = {};
  jobAttributes.label = "";
  jobAttributes.uniqueId = "";
  jobAttributes.defaultValue = "";
  jobAttributes.validationType = "None";
  jobAttributes.validValueList = "";
  jobAttributes.isRegexValid = true;
  return jobAttributes;
}

function makeConfig(testType, productInfo) {
  const defaultConfigs = productInfo.defaultConfigs;
  return JSON.parse(JSON.stringify(defaultConfigs[testType] || null));
}

function validConfig(testType, productInfo) {
  const defaultConfigValid = Object.assign(
    {},
    productInfo.defaultConfigs[testType] || null
  );

  for (const key of Object.keys(defaultConfigValid)) {
    defaultConfigValid[key] = true;
  }
  return JSON.parse(JSON.stringify(defaultConfigValid || null));
}

function testOptions(testTypes) {
  return Object.keys(testTypes);
}

function isRFVTest(testType) {
  if (testType === "Antenna Alignment" || testType === "Microwave Alignment") {
    return true;
  } else return false;
}

function getSchemaVersion(testType) {
  const SCHEMA_VERSION = isRFVTest(testType) ? "1.1" : "";
  return SCHEMA_VERSION;
}

function getMaxSetupCount(product, testType) {
  // Need to get more information on how to determine this value
  return 4;
}

function getMinSetupCount(product, testType) {
  // Need to get more information on how to determine this value
  return 1;
}

function getWavelengthsFromTestType(testType) {
  let wavelengths = [];
  if (testType.includes(",")) {
    const wavelengthStartIdx = testType.indexOf(".") + 1;
    wavelengths = testType.slice(wavelengthStartIdx).split(",");
  } else if (isWavelengthRange(testType)) {
    const wavelengthsRange = testType.split(".")[1];
    // set the default value to the starting wavelength of the wavelength range.
    wavelengths = [wavelengthsRange.split("-")[0]];
  } else if (isDecimalWavelengthTest(testType)) {
    const wavelengthStartIdx = testType.indexOf(".") + 1;
    wavelengths = [testType.slice(wavelengthStartIdx)];
  } else {
    wavelengths = testType.split(".").slice(1);
  }
  return wavelengths;
}

function isWavelengthRange(testType) {
  if (testType && testType.includes("-")) {
    return true;
  }
  return false;
}

function isDecimalWavelengthTest(testType) {
  for (let wavelength of ["1596.34", "1597.19", "1598.04", "1598.89"]) {
    if (testType.includes(wavelength)) {
      return true;
    }
  }
  return false;
}

function validateValue(val, inputType, min, max, pattern) {
  const validationType = inputType;
  let isInputValid = true;
  if (val === "") return false;
  const regex = new RegExp(pattern);
  switch (validationType) {
    case "string":
      isInputValid = regex.test(val);
      break;
    case "integer":
      isInputValid = regex.test(val);
      if (typeof min !== "undefined") {
        isInputValid &= val >= parseInt(min);
      }
      if (typeof max !== "undefined") {
        isInputValid &= val <= parseInt(max);
      }
      break;
    case "number":
      isInputValid = regex.test(val);
      if (typeof min !== "undefined") {
        isInputValid &= val >= parseFloat(min);
      }
      if (typeof max !== "undefined") {
        isInputValid &= val <= parseFloat(max);
      }
      break;
    default:
      break;
  }
  return isInputValid;
}

// deep copy the item
export function cloneItem(item) {
  return JSON.parse(JSON.stringify(item));
}

function HelpText({ label, value }) {
  const intl = useIntl();
  const helpKey = label + "Help";
  const filepath = makeUiFilepath(value);
  return intl.messages[helpKey] ? (
    <FormattedMessage id={helpKey} values={{ filepath: filepath }} />
  ) : (
    ""
  );
}

function makeUiFilepath(value) {
  if (typeof value === "string") {
    if (!value.startsWith("/user/")) {
      return value;
    }
    return value.replace("/user/", "");
  }
  return value;
}

function configValUI(configVal) {
  switch (configVal) {
    case "7a":
      return "7 ASCII Characters";
    case "8h":
      return "8 Hexadecimal Characters";
    case "4a_4h":
      return "4 ASCII and 4 Hexadecimal Characters";
    case "broadband":
      return "Broadband";
    case "selective":
      return "Selective PON";
    case "pon":
      return "TruePON";
    default:
      return configVal;
  }
}

export {
  initPlannedTest,
  initJobAttributes,
  testOptions,
  makeConfig,
  isRFVTest,
  getSchemaVersion,
  getWavelengthsFromTestType,
  getMaxSetupCount,
  getMinSetupCount,
  isWavelengthRange,
  updateDynamicConfig,
  validateValue,
  HelpText,
  configValUI,
};
