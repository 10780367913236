import { useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SendJobButton } from "./SendJobButton";
import { isSmartClassFiber } from "../products/productInfo";
import useSimulateDelay from "../hooks/useSimulateDelay";
import { EmptyLocationsDialog } from "./EmptyLocationsDialog";

function ActionButtons(props) {
  const { isOnx } = props.productInfo;
  if (props.accessToken) {
    const columnSize =
      isOnx || isSmartClassFiber(props.productInfo.product) ? 3 : 4;
    return (
      <Row>
        <Col sm={columnSize}>
          <LoadAndEditButton loadJob={props.loadJob} />
        </Col>
        {(isOnx || isSmartClassFiber(props.productInfo.product)) && (
          <Col sm={columnSize}>
            <SaveJobTemplateButton
              saveJobTemplate={props.saveJobTemplate}
              validateFields={props.validateFields}
            />
          </Col>
        )}
        {(isOnx || isSmartClassFiber(props.productInfo.product)) && (
          <Col sm={columnSize}>
            <SaveJobONXButton
              validateFields={props.validateFields}
              saveJobONX={props.saveJobONX}
              isOnx={props.isOnx}
              product={props.productInfo.product}
              isTestLocationsListEmpty={props.isTestLocationsListEmpty}
            />
          </Col>
        )}
        {!isOnx && !isSmartClassFiber(props.productInfo.product) && (
          <Col sm={columnSize}>
            <SaveJobButton saveJob={props.saveJob} />
          </Col>
        )}
        <Col sm={columnSize}>
          <SendJobButton
            sendJobToStrataSync={props.sendJobToStrataSync}
            ssServer={props.ssServer}
            accessToken={props.accessToken}
            productInfo={props.productInfo}
            validateFields={props.validateFields}
            isTestLocationsListEmpty={props.isTestLocationsListEmpty}
          />
        </Col>
      </Row>
    );
  } else {
    const columnSize = isOnx ? 4 : 6;
    return (
      <Row>
        <Col sm={columnSize}>
          <LoadAndEditButton loadJob={props.loadJob} />
        </Col>
        {isOnx && (
          <Col sm={columnSize}>
            <SaveJobTemplateButton
              saveJobTemplate={props.saveJobTemplate}
              validateFields={props.validateFields}
            />
          </Col>
        )}
        {(isOnx || isSmartClassFiber(props.productInfo.product)) && (
          <Col sm={columnSize}>
            <SaveJobONXButton
              validateFields={props.validateFields}
              saveJobONX={props.saveJobONX}
              isOnx={isOnx}
            />
          </Col>
        )}
        {!isOnx && !isSmartClassFiber(props.productInfo.product) && (
          <Col sm={columnSize}>
            <SaveJobButton saveJob={props.saveJob} />
          </Col>
        )}
      </Row>
    );
  }
}

function LoadAndEditButton(props) {
  const [show, setShow] = useState(false);
  const intl = useIntl();

  function loadJob(e) {
    function loadHandler(e) {
      var job = JSON.parse(e.target.result);
      props.loadJob(job);
    }

    var reader = new FileReader();
    reader.onload = loadHandler;
    reader.readAsText(e.target.files[0]);
    setShow(false);
  }

  return (
    <div>
      <Button variant="outline-primary" onClick={() => setShow(true)}>
        <FormattedMessage
          id="loadandeditbutton.message"
          defaultMessage="Load & Edit Job"
        />
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="loadandeditbutton.message"
              defaultMessage="Load & Edit Job"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.File
            label={intl.formatMessage({
              id: "loadandeditbutton.select",
              defaultMessage: "Select Job File",
            })}
            id="loadJobInput"
            onChange={loadJob}
            accept={".json"}
            data-browse={intl.formatMessage({
              id: "browse",
              defaultMessage: "Browse",
            })}
            custom
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function SaveJobTemplateButton(props) {
  const [open, setOpen] = useState(false);
  const [empty, setEmpty] = useState(false);
  let templateName = "";
  let emptyWarning = "Template name cannot be empty!";

  const [isDisabled, setDisabled] = useSimulateDelay(false);

  const changeTemplateName = (e) => {
    templateName = e.target.value;
  };
  const handleClick = () => {
    var isValid = props.validateFields();
    setDisabled(!isValid);
    if (isValid) {
      handleClickOpen();
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = () => {
    if (templateName.length !== 0) {
      setEmpty(false);
      setOpen(false);
      props.saveJobTemplate(templateName);
    } else {
      setEmpty(true);
    }
  };

  const handleCancel = () => {
    setEmpty(false);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outline-primary-onx"
        id="save-job-template"
        disabled={isDisabled}
        onClick={!isDisabled ? handleClick : null}
      >
        <FormattedMessage
          id="SaveJobTemplateButton.message"
          defaultMessage={isDisabled ? "Fields Invalid!" : "Save Job Template"}
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Save Job Template</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a template name.</DialogContentText>
          {empty && (
            <DialogContentText>
              <p style={{ color: "#FF0000" }}>{emptyWarning}</p>
            </DialogContentText>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Template Name"
            onChange={changeTemplateName}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SaveJobONXButton(props) {
  const [isDisabled, setDisabled] = useSimulateDelay(false);
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClick = () => {
    var isValid = props.validateFields();
    if (isSmartClassFiber(props.product) && props.isTestLocationsListEmpty()) {
      handleClickOpen();
    } else {
      setDisabled(!isValid);
      if (isValid) {
        props.saveJobONX();
      }
    }
  };

  return (
    <div>
      <Button
        variant="outline-primary-onx"
        disabled={isDisabled}
        onClick={!isDisabled ? handleClick : null}
      >
        {isDisabled ? "Fields Invalid!" : "Save Job"}
      </Button>
      <EmptyLocationsDialog
        openDialog={open}
        handleCancel={handleCancel}
        warningText={"Cannot save job without test locations list."}
      />
    </div>
  );
}

function SaveJobButton(props) {
  return (
    <Button variant="outline-primary" id="save-job" onClick={props.saveJob}>
      <FormattedMessage id="savejobbutton.message" defaultMessage="Save Job" />
    </Button>
  );
}

export default ActionButtons;
