const testTypesRFVision = {
  "Antenna Alignment": {
    testType: "Antenna Alignment",
    launchableType: "AntennaAlign",
    referenceInfo: [],
  },
  "Microwave Alignment": {
    testType: "Microwave Alignment",
    launchableType: "MicrowaveAlign",
    referenceInfo: [],
  },
  "Manual Step": {
    testType: "Manual Step",
    launchableType: "manualStep",
    NotSupportProfile: true,
    referenceInfo: [],
  },
};
export default testTypesRFVision;
