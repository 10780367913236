import React, { Component } from "react";

import {
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { DeleteTestButton, ToggleEditButton } from "./SelectOrDeleteTest.js";
import HelpRegex from "./HelpRegex.js";

export class JobAttributeInput extends Component {
  constructor(props) {
    super(props);
    this.changeAttributes = this.changeAttributes.bind(this);
    this.validateRegex = this.validateRegex.bind(this);
    this.state = {
      className: "text-success",
      valid: true,
    };
  }

  validateRegex(pattern) {
    try {
      new RegExp(pattern);
      this.setState({ className: "text-success", valid: true });
      return true;
    } catch (e) {
      this.setState({ className: "text-danger", valid: false });
      return false;
    }
  }

  changeAttributes(e) {
    const index = this.props.index;
    const newLabel = e.target.value;
    const key = e.target.id;
    const jobAttributes = this.props.jobAttributes;
    jobAttributes[key] = newLabel;
    if (
      jobAttributes["validationType"] === "Regular expression" &&
      key === "validValueList"
    ) {
      jobAttributes["isRegexValid"] = this.validateRegex(newLabel);
    }
    this.props.updateAttributesPlanItem(index, jobAttributes);
  }

  render() {
    const { label, uniqueId, defaultValue, validationType, validValueList } =
      this.props.jobAttributes;

    return (
      <Card body>
        <Row>
          <Col>
            <FormGroup controlId={"label"}>
              <FormLabel>
                <FormattedMessage id={"Label"} />
              </FormLabel>
              <FormControl
                type="text"
                value={label}
                onChange={this.changeAttributes}
              />
            </FormGroup>
          </Col>
          <Col xs={3} sm={2} lg={1} style={{ textAlign: "left" }}>
            <ToggleEditButton
              toggleEdit={this.props.toggleEdit}
              index={this.props.index}
            />
            <DeleteTestButton
              deleteTest={this.props.deleteAttribute}
              index={this.props.index}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup controlId={"uniqueId"}>
              <FormLabel>
                <FormattedMessage id={"Unique ID (JSON key)"} />
              </FormLabel>
              <FormControl
                type="text"
                value={uniqueId}
                onChange={this.changeAttributes}
              />
            </FormGroup>
            <FormGroup controlId={"defaultValue"}>
              <FormLabel>
                <FormattedMessage id={"Default Value"} />
              </FormLabel>
              <FormControl
                type="text"
                value={defaultValue}
                onChange={this.changeAttributes}
              />
            </FormGroup>
            <FormGroup controlId="validationType">
              <FormLabel>
                <FormattedMessage id={"Validation"} />
              </FormLabel>
              <FormControl
                as="select"
                value={validationType}
                onChange={this.changeAttributes}
              >
                <option>None</option>
                <option>Regular expression</option>
                <option>Valid Value List</option>
              </FormControl>
            </FormGroup>
            {validationType === "Regular expression" && (
              <FormGroup controlId={"validValueList"}>
                <FormLabel>
                  <FormattedMessage
                    id="RegEx (ie. “^[a-zA-Z0-9]+==$”)"
                    defaultMessage="RegEx (ie. “^[a-zA-Z0-9]*$”){helper}"
                    values={{
                      helper: <HelpRegex />,
                    }}
                  />
                </FormLabel>
                <FormControl
                  role="regex"
                  type="text"
                  value={validValueList}
                  onChange={this.changeAttributes}
                />
                {validValueList.length !== 0 && (
                  <Form.Text className={this.state.className}>
                    <FormattedMessage
                      id={
                        this.state.valid ? "Regex is valid" : "Regex not valid!"
                      }
                    />
                  </Form.Text>
                )}
              </FormGroup>
            )}
            {validationType === "Valid Value List" && (
              <FormGroup controlId={"validValueList"}>
                <FormLabel>
                  <FormattedMessage id="Valid Value List (comma-separated values; ie. Yes, No)" />
                </FormLabel>
                <FormControl
                  type="text"
                  value={validValueList}
                  onChange={this.changeAttributes}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
      </Card>
    );
  }
}
