const initialJob5800 = {
  jobNumber: "",
  customerName: "",
  testLocation: "",
  technicianId: "",
  logo: "",
  testPlan: [],
};

const initialJob4000 = {
  jobNumber: "",
  customerName: "",
  technicianId: "",
  contractorId: "",
  testLocation: "",
  testLocationB: "",
  cableId: "",
  logo: "",
  testPlan: [],
};
const initialJobOneExpertDSL = {
  jobId: "",
  technicianId: "",
  Date: ["", ""],
  testPlan: [],
  attributesPlan: [],
};
const initialJobOneExpertCATV = {
  jobId: "",
  technicianId: "",
  Date: ["", ""],
  testPlan: [],
  attributesPlan: [],
};
const initialJobNSC100 = {
  jobId: "",
  technicianId: "",
  Date: ["", ""],
  testPlan: [],
  attributesPlan: [],
};
const initialJobSCF = {
  jobId: "",
  technicianId: "",
  Date: ["", ""],
  testPlan: [],
  attributesPlan: [],
};
const initialJobOneExpertDSP = {
  jobId: "",
  technicianId: "",
  Date: ["", ""],
  testPlan: [],
  attributesPlan: [],
};

const initialJobRFVision = {
  jobId: "",
  technicianId: "",
  Date: ["", ""],
  testPlan: [],
  attributesPlan: [],
};

export {
  initialJob5800,
  initialJob4000,
  initialJobOneExpertDSL,
  initialJobOneExpertCATV,
  initialJobNSC100,
  initialJobOneExpertDSP,
  initialJobSCF,
  initialJobRFVision,
};
